import React, { useEffect, useState } from 'react'
import Sidebar from '../../components/Sidebar'
import '../../styles/Relatividad.css'
import Footers from '../../components/footers';

export default function Relativity() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <=600);
    useEffect(()=> {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 600);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    });

    const styles = {
        tpn:{
          padding:isMobile? '1rem': '1rem 10rem'
        },
        text:{
          fontSize: isMobile? '1.2rem': 'auto'
        },
        seetop:{
          fontSize: isMobile? '0.9rem':'auto'
        },
        seetop1:{
          margin: isMobile? '1rem':'2rem 15%'
        }
    }
  return (
    <>
        <Sidebar />
        <section className='tpcs'>
          <div className='mtpc'>
            <h1>Relatividad</h1>
            <hr />
          </div>
          <div style={styles.tpn} className='tpn'>

            <div className='tpo'>
              <a className='htp reo' href='/fisica/relatividad/postulados'>
                <h2 style={styles.text}>Postulados de la Relatividad Especial</h2>
                <div style={styles.seetop1} className='seetop'>
                    <h1 style={styles.seetop}>La Relatividad Especial fue una Teoría desarrollada por Albert Einstein y publicada en 1905. 
                    En esta se dan a conocer dos postulados fundamentales. El primer postulado dice que todas las leyes 
                    de la física son las mismas para los observadores en marcos de referencia inerciales. El segundo 
                    postulado nos habla acerca de la velocidad constante de la luz y cómo esta siempre es de 299,792,458 m/s.</h1>
                </div>
              </a>
            </div>

            <div className='tpt'>
              <a className='htp res' href='/fisica/relatividad/simultaneidad_relatividad'>
                <h2 style={styles.text}>Relatividad de la Simultaneidad</h2>
                <div style={styles.seetop1} className='seetop'>
                  <h1 style={styles.seetop}>La Relatividad de la Simulatneidad es una de las consecuencias de los Postulados de la Relatividad Especial. La Relatividad de la Simultaneidad se refiere a cuando un evento ocurre en distintos intervalos de tiempo dependiendo del marco de referencia de cada observador.</h1>
                </div>
              </a>
            </div>

          </div>
        </section>
        <Footers />
    </>
  )
}