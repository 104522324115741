import katex from 'katex'
import 'katex/dist/katex.min.css';

const equationsKatex = (equation) => {
    try {
        const renderedHtml = katex.renderToString(equation, {
            throwOnError: false,
            macros: {
                '\\ce': '\\text{\\text{}}'
            }
        });
        return renderedHtml;
    } catch (error){
        console.error('Error rendering equation:', error);
        return '<span style="color: red;">Error rendering equation</span>';
    }
}

export default equationsKatex;