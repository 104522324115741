import React, {useEffect, useState} from 'react'
import '../styles/Sidebar.css'
import Sidebar from '../components/Sidebar'
import VideoMate from '../assets/PhysicsBackground.mp4'
import '../styles/Topics_Mate.css'
import Footers from '../components/footers';
// import AOS from 'aos'
export default function Mate() {
  // useEffect(() => {
  //   AOS.init({duration:1000});
  // }, []);

  const [isMobile, setIsMobile] = useState(window.innerWidth <=1014);
    useEffect(()=> {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 1014);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    });

    const styles = {
        pse:{
            margin: isMobile? '2rem':'5rem 12rem'
        },
        prse:{
          display:'grid',
          gridTemplateColumns: isMobile? 'repeat(auto-fit, minmax(1, 1fr))': 'repeat(auto-fit, minmax(2, 1fr))',
          gridTemplateRows: isMobile? 'repeat(auto-fit, minmax(2, 1fr))':'minmax(100px, auto)'
        },
        blh:{
          gridRow: isMobile? '2':'1',
          gridColumn: isMobile? '1': '1',
          margin: isMobile? '2rem': '4rem'
        },
        imgprse:{
          gridColumn: isMobile? '1':'2',
          gridRow: isMobile? '1':'1',
          width: isMobile? '100%': 'auto',
          display: isMobile? 'flex':'auto', // Use flexbox in the parent container
          justifyContent: isMobile? 'center':'auto', // Center the image in the container
          alignItems: isMobile? 'center':'auto', // Center it vertically if needed
          padding: isMobile ? '2rem' : '0rem'
        },
        img:{
          width: isMobile? '80%': 'auto',
          // width: window.innerWidth < 600 ? '80%' : (isMobile ? '60%' : 'auto'),
          margin: isMobile? '0 auto': 'auto', // Center the image horizontally
          display: isMobile? 'block':'auto' 
        },
        gridboxtops:{
          display:'grid',
          gridTemplateColumns: isMobile? 'repeat(auto-fit, minmax(1, 1fr))': 'repeat(auto-fit, minmax(2, 1fr))',
          gridTemplateRows: isMobile? 'repeat(auto-fit, minmax(4, 1fr))':'minmax(100px, auto)'
        },
        meca:{
          gridColumn: isMobile? '1':'1',
          gridRow: isMobile? '1':'1',
        },
        relat:{
          gridColumn: isMobile? '1':'2',
          gridRow: isMobile? '2':'1',
        },
        descriptions:{
          // margin: isMobile? '-33rem 0': '-32rem 0',
          // margin: window.innerWidth < 600 ? '-35rem 0': '-32rem 0',
          margin: window.innerWidth < 600 ? '-33rem 0' : (isMobile ? '-33rem 0' : '-32rem 0')
        },
    }
  return (
    <>
      <Sidebar />
      <section className='grid'>
        <div className='bg'>
          <div className="bg-f">
            <video src={VideoMate} autoPlay loop muted />

            <div className="title-f">
              <h1>Matemáticas</h1>
            </div>
          </div>
        </div>

        <div style={styles.pse} className='pse'>
          <div style={styles.prse} className='prse'>
            <div style={styles.blh}  className='blh'>
              <blockquote>Las matemáticas son la creación más poderosa y bella del espíritu humano.</blockquote>
              <h3>Stefan Banach</h3>
            </div>
            <div style={styles.imgprse} className='img-prse'>
              <img style={styles.img} src={require('../assets/images/Stefan_Banach.jpg')} />
            </div>
          </div>
        </div>
        
        <div style={styles.gridboxtops} className='grid-box-tops'>
          {/* <div className='top'>
            <div className='top-box'> */}
              <a style={styles.meca} href='/mate/algebra' className='top alg'>
                <div className='names'><h2>Álgebra</h2></div>
                <div style={styles.descriptions} className='descriptions'><h2>El álgebra es la rama de las matemáticas más aplicada en el mundo real. Desde la construcción de edificios hasta formulaciones de teorías científicas.</h2></div>
              </a>
            {/* </div>
          </div> */}

          {/* <div className='top'>
            <div className='top-box'> */}
              <a style={styles.relat} href='#' className='top calc'>
                <div className='names'><h2>Cálculo</h2></div>
                <div style={styles.descriptions} className='descriptions'><h2>Próximamente.</h2></div>
              </a>
            {/* </div>
          </div> */}

          {/* <div className='top'>
            <div className='top-box'> */}
              {/* <a href='#' className='top mecac'>
                <div className='names'><h2>Mecánica cuántica</h2></div>
                <div className='descriptions'><h2>La Mecánica Cuántica nace con la Radiación del Cuerpo Negro por
                Max Planck en 1905, descubrió que los fotones pequeños paquetes de energía llamados "quanta".
                La Mecánica cuántica estudia la naturaleza de los sistemas subatómicos.</h2></div>
              </a> */}
            {/* </div>
          </div> */}

          {/* <div className='top'>
            <div className='top-box'> */}
              {/* <a href='#' className='top nuuni'>
                <div className='names'><h2>Nuestro Universo</h2></div>
                <div className='descriptions'><h2>La Teoría de la Relatividad fue publicada en los años 1905 
                (relatividad especial) y 1915 (relatividad general) por Albert Einstein. Esta teoría nos habla 
                acerca de las leyes de los cuerpos en marcos de referencia distinta y la naturaleza de la gravedad.</h2></div>
              </a> */}
            {/* </div>
          </div> */}
        </div>
      </section> 
      <Footers />
    </>
  )
}