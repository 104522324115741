import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone } from '@fortawesome/free-solid-svg-icons'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import {faYoutube, faXTwitter, faInstagram, faFacebook} from '@fortawesome/free-brands-svg-icons'
import '../styles/footers.css'
export default function footers() {
  return (
    <div>
        <div className='footer-content'>
            <div className='mainname'>
                <h1>The Big Science</h1>
            </div>
            <div className='content-f'>
                <div className='social-m'>
                    <h2>Redes Sociales</h2>
                    <ul className='social-i'>
                        <li className='nodec'><a href=''><FontAwesomeIcon className="so" icon={faYoutube} /></a></li>
                        <li className='nodec'><a href=''><FontAwesomeIcon className="so1" icon={faXTwitter} /></a></li>
                        <li className='nodec'><a href='https://www.instagram.com/thebigscience/'><FontAwesomeIcon className="so2" icon={faInstagram} /></a></li>
                        <li className='nodec'><a href='https://www.facebook.com/profile.php?id=61563732307734'><FontAwesomeIcon className="so3" icon={faFacebook} /></a></li>
                    </ul>
                </div>
                <div className='contactos'>
                    <h2>Contactos</h2>
                    <ul>
                        {/* <li className='nodec'><a className='phone'><FontAwesomeIcon className='phone-i' icon={faPhone}></FontAwesomeIcon><h4>+52 888888888</h4></a></li> */}
                        <li className='nodec'><a href='mailto: thebigscience0@gmail.com' className='email'><FontAwesomeIcon className='email-i' icon={faEnvelope}></FontAwesomeIcon><h4>thebigscience0@gmail.com</h4></a></li>
                    </ul>
                </div>
                <div className='policies'>
                    <h2>Políticas</h2>
                    <ul>
                        <li className='nodec'><a href='/politicas_de_privacidad' className='pandp'>Políticas de Privacidad</a></li>
                        <li className='nodec'><a href='/terminos_y_condiciones' className='pandp'>Términos y Condiciones</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
  )
}