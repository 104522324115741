import React, { useEffect, useState } from 'react'
import Sidebar from '../../components/Sidebar'
import '../../styles/Python.css'
import Footers from '../../components/footers';

export default function Python() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <=600);
    useEffect(()=> {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 600);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    });

    const styles = {
        tpn:{
          padding:isMobile? '1rem': '1rem 10rem'
        },
        text:{
          fontSize: isMobile? '1.2rem': 'auto'
        },
        seetop:{
          fontSize: isMobile? '0.9rem':'auto'
        },
        seetop1:{
          margin: isMobile? '1rem':'2rem 15%'
        }
    }
  return (
    <>
        <Sidebar />
        <section className='tpcs'>
          <div className='mtpc'>
            <h1>Python</h1>
            <hr />
          </div>
          <div style={styles.tpn} className='tpn'>

            <div className='tpo'>
              <a className='htp pth' href='/programa/python/instalacion_herramientas'>
                <h2 style={styles.text}>Instalación de herramientas</h2>
                <div style={styles.seetop1} className='seetop'>
                    <h1 style={styles.seetop}>Este artículo es una guía para instalar las herramientas necesarias para programar en el lenguaje Python. El editor de código que se utilizará será Visual Studio Code.</h1>
                </div>
              </a>
            </div>

            <div className='tpt'>
              <a className='htp thp' href='/programa/python/variables_listas '>
                <h2 style={styles.text}>Variables</h2>
                <div style={styles.seetop1} className='seetop'>
                  <h1 style={styles.seetop}>Las variables en Python son cajas en donde se pueden guardar valores números o de caracteres especiales.</h1>
                </div>
              </a>
            </div>

          </div>
        </section>
        <Footers/>
    </>
  )
}