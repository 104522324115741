import React, { useEffect, useState } from 'react'
import Sidebar from '../../../components/Sidebar'
import Footers from '../../../components/footers';

export default function Instherr() {
    const [isMobile, setIsMobile] = useState(window.innerWidth <=600);
    useEffect(()=> {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 600);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    });

    const styles = {
        bcgdsm:{
            padding: isMobile? '1.8rem':'2rem 25%'
        }
    }
  return (
    <>
    <Sidebar />
    <section style={styles.bcgdsm} className='bcgdsm'>

            <div className='titami'>
                <h1>Instalación de Herramientas Python</h1>
                <h4>Esdras Hernández</h4>
                <h5>6 minutos de lectura</h5>
            </div>

            <div className='igsm'>
                <img src={require('../../../assets/images/15dda148-5acf-4c5e-afe5-84455290d240.jpg')}/>
            </div>

            <div className='dessm'>
                <h1>Windows</h1>
                <ul>
                    <li>1. Haz click en el siguiente enlace: <a href='https://www.python.org/downloads/'>https://www.python.org/downloads/</a></li>
                    <li>2. Haz click en Descargar Python 3.12.4 (última versión) para Windows.</li>
                </ul>
            </div>
            <div className='igsm'>
                <img src={require('../../../assets/images/scr1.png')} />
            </div>
            <div className='dessm'>
                <ul>
                    <li>3. Haz click en el archivo descargado y haz click en “Run”.</li>
                </ul>
            </div>
            <div className='igsm'>
                <img src={require('../../../assets/images/scr6.png')}/>
            </div>
            <div className='dessm'>
                <ul>
                    <li>4. Haz click en Install Now.</li>
                </ul>
            </div>
            <div className='igsm'>
                <img src={require('../../../assets/images/scr7.png')} />
            </div>
            <div className='dessm'>
                <ul>
                    <li>5. Selecciona todas las opciones.</li>
                </ul>
            </div>
            <div className='igsm'>
                <img src={require('../../../assets/images/scr8.png')} />
            </div>

            <div className='dessm'>
                <ul>
                    <li>6. Haz click en Install.</li>
                </ul>
            </div>
            <div className='igsm'>
                <img src={require('../../../assets/images/scr9.png')} />
            </div>

            <div className='dessm'>
                <ul>
                    <li>7. Haz click en el siguiente enlace: <a href='https://code.visualstudio.com/download'>https://code.visualstudio.com/download</a>.</li>
                    <li>8. Haz click en descargar Visual Studio Code para Windows.</li>
                </ul>
            </div>
            <div className='igsm'>
                <img src={require('../../../assets/images/S.png')} />
            </div>

            <div className='dessm'>
                <ul>
                    <li>9. Ejecuta el archivo y se abrirá el programa.</li>
                    <li>10. Por último, haz click en el apartado de extensiones y en la barra de búsqueda escribimos Python, damos click en la primera opción (Microsoft) y lo instalamos.</li>
                </ul>
            </div>
            <div className='igsm'>
                <img src={require('../../../assets/images/scr11.png')} />
            </div>

            <div className='dessm'>
                <h1>MacOS</h1>
                <ul>
                    <li>1. Haz click en el siguiente enlace: <a href='https://www.python.org/downloads/'>https://www.python.org/downloads/</a></li>
                    <li>2. Haz click en Descargas o Downloads.</li>
                    <li>3. Hax click en MacOS.</li>
                </ul>
            </div>
            <div className='igsm'>
                <img src={require('../../../assets/images/scr3.png')} />
            </div>

            <div className='dessm'>
                <ul>
                    <li>4. Haz click en la última versión de Python.</li>
                </ul>
            </div>
            <div className='igsm'>
                <img src={require('../../../assets/images/scr4.png')} />
            </div>

            <div className='dessm'>
                <ul>
                    <li>5. Ejecuta el programa y acepta todos los términos y haz click en continuar.</li>
                    <li>6. Haz click en el siguiente enlace: <a href='https://code.visualstudio.com/download'>https://code.visualstudio.com/download</a>.</li>
                    <li>7. Haz click en descargar Visual Studio Code para MacOS.</li>
                </ul>
            </div>
            <div className='igsm'>
                <img src={require('../../../assets/images/scr10.png')} />
            </div>

            <div className='dessm'>
                <ul>
                    <li>8. Ejecuta el archivo y se abrirá el programa.</li>
                    <li>9. Por último, haz click en el apartado de extensiones y en la barra de búsqueda escribimos Python, haz click en la primera opción y lo instalas</li>
                </ul>
            </div>
            <div className='igsm'>
                <img src={require('../../../assets/images/scr11.png')} />
            </div>

    </section>
    <Footers />
    </>
  )
}