import React, { useEffect, useState } from 'react'
import Sidebar from '../../../components/Sidebar'
import '../../../styles/Simul.css'
import Footers from '../../../components/footers';

export default function Simurela() {
    const [isMobile, setIsMobile] = useState(window.innerWidth <=600);
    useEffect(()=> {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 600);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    });

    const styles = {
        bcgdsm:{
            padding: isMobile? '1.8rem':'2rem 25%'
        }
    }
  return (
    <>
    <Sidebar />
    <section style={styles.bcgdsm} className='bcgdsm'>

            <div className='titami'>
                <h1>Relatividad de la Simultaneidad</h1>
                <h4>Esdras Hernández</h4>
                <h5>12 minutos de lectura</h5>
            </div>

            <div className='igsm'>
                <img src={require('../../../assets/images/8c82802e-7032-4109-aedb-6af3d4e2337d.png')}/>
            </div>

            <div className='dessm'>
                <h2>En el artículo anterior se habló de los postulados de la Relatividad de Einstein en el que concluimos
                 dos cosas: las leyes de la física son iguales en cualquier marco de referencia inercial, y la velocidad 
                 de la luz es una constante universal independiente de cualquier evento o fuente.</h2>
                 <h2>Ahora, veamos las consecuencias de estos dos postulados. El primero es la simultaneidad relativa, 
                 y es que dos eventos que son simultáneos en un marco de referencia pueden no serlo en otro que se mueve en relación al primero.</h2>
            </div>
            <div className='igsm'>
                <img src={require('../../../assets/images/SCR-1.png')} />
                <h2>Créditos: Openstax</h2>
            </div>
            <div className='dessm'>
                <h2>¿Los intervalos de tiempo son los mismos para todos en cualquier evento? Imagínate que estás en un 
                carrera a pie, la pregunta es: ¿el tiempo real transcurrido de la carrera es el mismo para todos? 
                La respuesta es que nadie podría decir que el tiempo real era diferente para el corredor y para el 
                reloj que indica el tiempo de la carrera. Si se revisa cómo se mide el tiempo, se observará que el tiempo 
                transcurrido depende del movimiento relativo de un observador con respecto al proceso que se está midiendo.</h2>
                <h2>Imagínate una mujer sentada justamente en medio de un vagón con dos lámparas en lados opuestos de ella. 
                Un rayo de luz es emitido por cada destello de las lámparas y se mueven en dirección a la mujer. El vagón se
                 mueve a la derecha a una cierta velocidad constante. Ahora, imagínate que hay un hombre fuera del vagón de 
                 cara al tren, y cuando este pasa verá que ambos rayos de luz llegan simultáneamente, al medir las distancias
                  de donde provinieron los rayos de luz, encuentra que son iguales por lo que concluye que el evento se dio de
                   manera simultánea.</h2>
            </div>
            <div className='igsm'>
                <img src={require('../../../assets/images/SCR-2.png')}/>
                <h2>Créditos: Openstax</h2>
            </div>
            <div className='dessm'>
                <h2>Pero, la mujer al estar dentro del vagón y este mismo se mueve a la derecha, verá que el rayo de luz 
                proveniente de la derecha llegará más rápidamente, ¿por qué? Esto es debido al movimiento del vagón. 
                La mujer al medir las distancias de las dos lámparas encontrará que son iguales, y termina por decir que 
                los rayos de luz no se emitieron de manera simultánea.</h2>
                <h2>Vamos a esclarecer más las cosas. Lo primero es que para ambos observadores las lámparas se encontraban
                 a distancias iguales, esto quiere decir que el problema no está relacionado con las distancias. Lo segundo,
                  en el ejemplo nos señala que el hombre está fuera del vagón en movimiento por lo que se deduce que su 
                  marco de referencia es el de reposo con respecto al vagón, también se señala que la mujer está en estado 
                  de reposo con respecto a el vagón, sin embargo, con respecto al hombre ella está en movimiento constante.</h2>
            </div>
            <div className='igsm'>
                <img src={require('../../../assets/images/SCR-3.png')} />
                <h2>Créditos: Openstax</h2>
            </div>
            <div className='dessm'>
                <h2>Ahora, ¿cómo entendemos esto? El hombre al estar en reposo con respecto al vagón moviéndose a la 
                derecha, puede ver que el evento fue simultáneo debido a que él al no estar en movimiento, la luz llega 
                simultáneamente a él. Sin embargo, la mujer debido a que el vagón se mueve a la derecha, la luz de la 
                derecha llegará primero, no es debido a que la luz de la derecha sea más rápida, sino porque la el vagón 
                se mueve a la derecha y por lo tanto la mujer se acerca a la luz de la derecha mientras se aleja de la 
                luz proveniente de la lámpara contraria.</h2>
            </div>
            <div className='igsm'>
                <img src={require('../../../assets/images/e480ff13-8792-47e0-900e-6bbac1958c04.png')} />
            </div>
            <div className='dessm'>
                <h2>Pensemos en la partícula de la luz el fotón, al dar un destello las lámparas, se emite un fotón, 
                ahora, ese fotón no está sujeto al movimiento del tren porque fue liberado y ahora está en un espacio
                 vacío entre el y la mujer, al cabo de unos momentos los fotones se moverán exactamente iguales, sin 
                 embargo, el vagón también lo hará provocando que la mujer se aleje del fotón de la izquierda para 
                 acercarse al fotón de la derecha, aquí se observa que los fotones viajan a la misma velocidad, sin 
                 embargo, es la mujer quien por estar en un vagón que se mueve a la derecha, provoca que se acerque 
                 cada vez más al fotón de la derecha.</h2>
                 <h2>Cabe recalcar que estos fueron experimentos mentales, es decir, que el efecto será despreciable en 
                 escalas cotidianas debido a la velocidad de la luz.</h2>
            </div>
            <div className='dessm'>
                <h1>Referencias</h1>
                 <h2>https://openstax.org/books/f%C3%ADsica-universitaria-volumen-3/pages/5-2-relatividad-de-la-simultaneidad</h2>
            </div>

    </section>
    <Footers />
    </>
  )
}