import React, { useEffect, useState } from 'react'
import Sidebar from '../../../components/Sidebar'
import Footers from '../../../components/footers';

export default function Posrel() {
    const [isMobile, setIsMobile] = useState(window.innerWidth <=600);
    useEffect(()=> {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 600);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    });

    const styles = {
        bcgdsm:{
            padding: isMobile? '1.8rem':'2rem 25%'
        }
    }
  return (
    <>
    <Sidebar />
    <section style={styles.bcgdsm} className='bcgdsm'>

            <div className='titami'>
                <h1>Postulados de la Relatividad Especial</h1>
                <h4>Esdras Hernández</h4>
                <h5>8 minutos de lectura</h5>
            </div>

            <div className='igsm'>
                <img src={require('../../../assets/images/f8a7c6b4-cdbc-4a4e-84f4-6cadeea3e094.png')}/>
            </div>

            <div className='dessm'>
                <h1>La Invariancia de Galileo</h1>
                <h2>Muchos tienden a pensar que la Relatividad fue formulada enteramente por el físico Albert Einstein, sin embargo, 
                esto no es del todo correcto. Muchas de las ideas que Einstein tomó para formular la relatividad fueron de Maxwell,
                 Max Planck, Galileo, etc.</h2>
                 <h2>Galileo dijo que para una persona que esté en un marco de referencia en reposo o movimiento constante,
                  no hay forma de saber la velocidad del marco de referencia en el que se está haciendo experimentos mecánicos
                   si los hace en ese marco de referencia.</h2>
            </div>
            <div className='igsm'>
                <img src={require('../../../assets/images/76f4ab5c-f669-4b4c-8419-57bd18434935.png')} />
            </div>
            <div className='dessm'>
                <h2>Vamos a esclarecer las cosas. Imagínate que estás en tus entrenamientos de basquetbol, tu entrenadora 
                les pide a ti y a tus compañeros dar 5 vueltas a la cancha corriendo y botando el balón, tu comienzas tu 
                primera vuelta con una velocidad constante, al completar la primera vuelta, pasas al lado de tu entrenadora 
                y te dice que no botes el balón delante de ti, sino que lo botes en línea recta, en ese momento tu volteas 
                al ver el balón botando en el suelo mientras corres y lo ves botar en línea recta. La pregunta es: 
                ¿quién está correcto, tu o la entrenadora?</h2>
                <h2>Bueno, de acuerdo con Galileo, la respuesta es que ambos tienen razón. ¿Por qué? La respuesta está en 
                los marcos de referencia. Tu marco de referencia en ese momento es de velocidad constante o movimiento 
                constante y el marco de referencia de tu entrenadora es el de reposo o sin movimiento, por lo que es un 
                evento que es observado en dos marcos de referencia (puntos de vista para simplificar) diferentes, y dado
                 que no existe un marco de referencia absoluto (que todos vean experimentos y eventos de la misma forma),
                  se dice entonces que es relativo.</h2>
            </div>
            <div className='igsm'>
                <img src={require('../../../assets/images/cdc840e0-5638-46a0-a831-eab089a80c54.png')}/>
            </div>
            <div className='dessm'>
                <h2>Un marco de referencia con movimiento constante es similar al comportamiento de un marco de referencia
                 en reposo debido, en este caso, cuando tu botes el balón, verás que el balón tiene un movimiento idéntico
                  al tuyo (que es constante) y cuando regrese a tus manos verás que el balón estará en reposo. 
                  Sin embargo, en el marco de referencia de tu entrenadora (reposo), verá un cuerpo en movimiento 
                  constante (que es el tuyo) y un balón que cuando lo botas no está en reposo, sino que iniciará con una 
                  velocidad horizontal desde el punto de vista de tu entrenadora. Con esto concluimos que el movimiento y 
                  espacio son relativos.</h2>
                <h2>Ahora, si tu en algún momento te detienes para descansar unos segundos, tu marco de referencia ahora 
                será el que tiene tu entrenadora, es decir, el reposo y cuando un compañero tuyo pase al lado tuyo con un 
                movimiento constante verás que al botar el balón, verás que hará la forma de una parábola, que es 
                exactamente lo que veía antes tu entrenadora.</h2>
            </div>
            <div className='igsm'>
                <img src={require('../../../assets/images/0c226216-a29c-4bbb-95f0-269758d2606f.png')} />
            </div>
            <div className='dessm'>
                <h1>El principio de Relatividad de Einstein</h1>
                <h2>Einstein expresó su primer postulado de Relatividad de la siguiente manera:</h2>
                <h2>“Todas las leyes de la física son exactamente las mismas para cada observador en cada marco de 
                referencia que está en reposo o moviéndose con una velocidad relativa uniforme. Esto significa que no 
                hay ningún experimento que se pueda realizar dentro de un marco de referencia que revele si éste está 
                en reposo o moviéndose a una velocidad uniforme.”</h2>
                <h2>Pero, ¿a qué se refiere Albert Einstein con esto? Este principio no es más que la invariancia de 
                Galileo pero ahora aplicado a “todas las leyes de la física” en palabras de Einstein. Galileo afirmó 
                esto mismo pero solamente para experimentos mecánicos o leyes mecánicas.</h2>
            </div>

            <div className='igsm'>
                <img src={require('../../../assets/images/6a3d8f5c-f268-415a-8f05-453b7d70a905.png')} />
            </div>
            <div className='dessm'>
                <h2>Los marcos de referencia en reposo o en movimiento constante son conocidos mayormente como 
                “marcos inerciales” porque cumplen con la Primera Ley de Newton: La Ley de la Inercia. Para referirse 
                a marcos que no están en reposo o movimiento constante son conocidos como marcos no inerciales.</h2>
                <h2>Esta primera teoría que formuló Einstein se le llama Teoría de la Relatividad Especial 
                debido a que solo funciona en marcos de referencia inerciales.</h2>
                <h2>El segundo postulado que Einstein formuló, tiene que ver con la velocidad de la luz.</h2>
            </div>

            <div className='igsm'>
                <img src={require('../../../assets/images/3c1af05e-2c28-4b85-bf85-7849fb9b511b.png')} />
            </div>
            <div className='dessm'>
                <h2>Años antes que Einstein, el físico escocés James Clerk Maxwell demostró que la luz es una onda 
                electromagnética y que esta viaja a la velocidad de la luz. Einstein pensó qué pasaría si se alcanzara
                 la velocidad de la luz, ¿qué vería? La respuesta es que tendría que ver los valles y crestas de la onda, 
                 sin embargo, esto contradecía a Maxwell que postuló que la luz no puede estar parada.</h2>
                <h2>Imagina un tren que corre a 100 km/h (velocidad constante). Si una persona dentro del tren enciende 
                un láser. Sabemos que la velocidad de la luz se mueve a 299,792,458 metros por segundo. Si un pasajero 
                mide la velocidad del láser, obtendrá esta misma cantidad. Ahora, imagínate que hay un persona fuera del
                 tren, es decir, un marco de referencia en reposo (el marco de referencia del tren es constante), 
                 ¿cuál sería la velocidad del láser para esa persona? Si el tren tiene una velocidad de 100 km/h y se 
                 encendió un láser dentro del tren, lo intuitivo sería sumar la velocidad del tren más la velocidad de 
                 la luz. La respuesta es que no, es decir, la velocidad de la luz no cambia con respecto a cualquier 
                 marco de referencia, ahora la pregunta es: ¿cómo sabemos esto? </h2>
            </div>

            <div className='igsm'>
                <img src={require('../../../assets/images/3db17103-398c-4fa0-b573-fe77a5f2659a.png')} />
            </div>
            <div className='dessm'>
                <h2>Hipólito Fizeau hizo un experimento análogo al tren: lanzar un rayo de luz a través de un tubo con
                 agua en movimiento, Fizeau quería saber si la velocidad del agua se sumaba con la velocidad de la luz, 
                 al final descubrió que la velocidad de la luz no cambió.</h2>
                <h2>En palabras de Einstein: “La luz y todas las demás formas de radiación electromagnética se propagan
                 en el espacio vacío con una velocidad constante c que es independiente del movimiento del observador o 
                 del cuerpo emisor.”</h2>
                 <h2>La velocidad de la luz es una constante universal, es decir, que no cambia y es expresada por la 
                 letra c = 299.792.458 m/s.</h2>
                 <h2>Es importante recordar que este principio sólo se aplica a los observadores y las fuentes que están en marcos de 
                 referencia inerciales.</h2>
            </div>
            <div className='dessm'>
                <h1>Referencias:</h1>
                <h2>https://culturacientifica.com/2017/12/05/principio-relatividad-3-la-invariancia-galileo/</h2>
                 <h2>https://culturacientifica.com/2017/12/12/principio-relatividad-4-la-version-einstein/</h2>
            </div>

    </section>
    <Footers />
    </>
  )
}