import React from 'react'
import * as FaIcons from 'react-icons/fa'
// import * as AiIcons from 'react-icons/ai'
// import * as IoIcons from 'react-icons/io'
import * as RiIcons from 'react-icons/ri'
import * as CgIcons from "react-icons/cg";
import * as DiIcons from 'react-icons/di'
import * as MdIcons from 'react-icons/md'
import * as TbIcons from 'react-icons/tb'
export const Siderdata = [
    {
        title: 'Home',
        path: '/',
        icon: <FaIcons.FaHome />,
    },
    {
        title:"Física",
        path:'',
        icon: <MdIcons.MdScience />,
        iconClosed: <RiIcons.RiArrowDownSFill />,
        iconOpened: <RiIcons.RiArrowUpSFill />,
        subNav:[
            {
                title:'Física',
                path: '/fisica',
                icon: <MdIcons.MdScience />,
                iconClosed: <RiIcons.RiArrowDownSFill />,
                iconOpened: <RiIcons.RiArrowUpSFill />,
            },
            {
                title:'Relatividad',
                path: '/fisica/relatividad',
                icon: <CgIcons.CgSandClock />,
                iconClosed: <RiIcons.RiArrowDownSFill />,
                iconOpened: <RiIcons.RiArrowUpSFill />,
                // subNav:[
                //     {
                //         title: "CI",
                //         path: '/relatividad/ci',
                //         icon: <IoIcons.IoIosPaper />
                //     },
                //     {
                //         title: "CI",
                //         path: '/relatividad/ci',
                //         icon: <IoIcons.IoIosPaper />
                //     }
                // ]
            },

            {
                title:'Mecánica Cuántica',
                path: '/fisica/mecanicacuantica',
                icon: <DiIcons.DiAtom />,
                iconClosed: <RiIcons.RiArrowDownSFill />,
                iconOpened: <RiIcons.RiArrowUpSFill />,
                // subNav:[
                //     {
                //         title: "CI",
                //         path: '/mecanicacuantica/ci',
                //         icon: <IoIcons.IoIosPaper />
                //     },
                //     {
                //         title: "CI",
                //         path: '/mecanicacuantica/ci',
                //         icon: <IoIcons.IoIosPaper />
                //     }
                // ]
            }

            // {
            //     title:'Nuestro Universo',
            //     path: '/nuestrouniverso',
            //     icon: <FaIcons.FaRocket />
            // }

        ]
    },
    {
        title:"Programación",
        path:'#',
        icon: <FaIcons.FaCode />,
        iconClosed: <RiIcons.RiArrowDownSFill />,
        iconOpened: <RiIcons.RiArrowUpSFill />,
        subNav:[
            {
                title:'Programación',
                path: '/programa',
                icon: <FaIcons.FaCode />,
                iconClosed: <RiIcons.RiArrowDownSFill />,
                iconOpened: <RiIcons.RiArrowUpSFill />,
            },
            {
                title:'Python',
                path: '/programa/python',
                icon: <FaIcons.FaPython />,
                iconClosed: <RiIcons.RiArrowDownSFill />,
                iconOpened: <RiIcons.RiArrowUpSFill />,
                // subNav:[
                //     {
                //         title: "C",
                //         path: '/classical_mechanics/ci',
                //         icon: <IoIcons.IoIosPaper />
                //     },
                //     {
                //         title: "C",
                //         path: '/classical_mechanics/ci',
                //         icon: <IoIcons.IoIosPaper />
                //     }
                // ]
            }
        ]
    },
    {
        title:"Matemáticas",
        path:'',
        icon: <TbIcons.TbMathFunction />,
        iconClosed: <RiIcons.RiArrowDownSFill />,
        iconOpened: <RiIcons.RiArrowUpSFill />,
        subNav:[
            {
                title:'Matemáticas',
                path: '/mate',
                icon: <TbIcons.TbMathFunction />,
                iconClosed: <RiIcons.RiArrowDownSFill />,
                iconOpened: <RiIcons.RiArrowUpSFill />,
            },
            {
                title:'Algebra',
                path: '/mate/algebra',
                icon: <TbIcons.TbMath />,
                iconClosed: <RiIcons.RiArrowDownSFill />,
                iconOpened: <RiIcons.RiArrowUpSFill />,
                // subNav:[
                //     {
                //         title: "C",
                //         path: '/classical_mechanics/ci',
                //         icon: <IoIcons.IoIosPaper />
                //     },
                //     {
                //         title: "C",
                //         path: '/classical_mechanics/ci',
                //         icon: <IoIcons.IoIosPaper />
                //     }
                // ]
            }
        ]
    }
]

export default Siderdata
// export const SidebarData =[
//     {

//         title:'Mecánica Clásica',
//         path: '/classical_mechanics',
//         icon: <FaIcons.FaRocket />,
//         iconClosed: <RiIcons.RiArrowDownSFill />,
//         iconOpened: <RiIcons.RiArrowUpSFill />,
//         subNav:[
//             {
//                 title: "C",
//                 path: '/classical_mechanics/ci',
//                 icon: <IoIcons.IoIosPaper />
//             },
//             {
//                 title: "C",
//                 path: '/classical_mechanics/ci',
//                 icon: <IoIcons.IoIosPaper />
//             }
//         ]
//     },
//     {
//         title:'Relatividad',
//         path: '#',
//         icon: <CgIcons.CgSandClock />,
//         iconClosed: <RiIcons.RiArrowDownSFill />,
//         iconOpened: <RiIcons.RiArrowUpSFill />,
//         subNav:[
//             {
//                 title: "CI",
//                 path: '/relatividad/ci',
//                 icon: <IoIcons.IoIosPaper />
//             },
//             {
//                 title: "CI",
//                 path: '/relatividad/ci',
//                 icon: <IoIcons.IoIosPaper />
//             }
//         ]
//     },
//     {
//         title:'Mecánica Cuántica',
//         path: '/mecanicacuantica',
//         icon: <DiIcons.DiAtom />,
//         iconClosed: <RiIcons.RiArrowDownSFill />,
//         iconOpened: <RiIcons.RiArrowUpSFill />,
//         subNav:[
//             {
//                 title: "CI",
//                 path: '/mecanicacuantica/ci',
//                 icon: <IoIcons.IoIosPaper />
//             },
//             {
//                 title: "CI",
//                 path: '/mecanicacuantica/ci',
//                 icon: <IoIcons.IoIosPaper />
//             }
//         ]
//     },
//     {
//         title:'Nuestro Universo',
//         path: '/nuestrouniverso',
//         icon: <DiIcons.DiAtom />,
//     }
// ]
// export default SidebarData;
// export default function SidebarData() {
//   return (
//     [
//     {
//         title:'Classical Mechanics',
//         path: '/classical_mechanics',
//         icon: <FaIcons.FaRocket />,
//         iconClosed: <RiIcons.RiArrowDownSFill />,
//         iconOpened: <RiIcons.RiArrowUpSFill />,
//         subNav:[
//             {
//                 title: "CI",
//                 path: '/classical_mechanics/ci',
//                 icon: <IoIcons.IoIosPaper />
//             },
//             {
//                 title: "CI",
//                 path: '/classical_mechanics/ci',
//                 icon: <IoIcons.IoIosPaper />
//             }
//         ]
//     },
//     {
//         title:'Relatividad',
//         path: '/relatividad',
//         icon: <CgIcons.CgSandClock />,
//         iconClosed: <RiIcons.RiArrowDownSFill />,
//         iconOpened: <RiIcons.RiArrowUpSFill />,
//         subNav:[
//             {
//                 title: "CI",
//                 path: '/relatividad/ci',
//                 icon: <IoIcons.IoIosPaper />
//             },
//             {
//                 title: "CI",
//                 path: '/relatividad/ci',
//                 icon: <IoIcons.IoIosPaper />
//             }
//         ]
//     },
//     {
//         title:'Mecánica Cuántica',
//         path: '/mecanicacuantica',
//         icon: <DiIcons.DiAtom />,
//         iconClosed: <RiIcons.RiArrowDownSFill />,
//         iconOpened: <RiIcons.RiArrowUpSFill />,
//         subNav:[
//             {
//                 title: "CI",
//                 path: '/mecanicacuantica/ci',
//                 icon: <IoIcons.IoIosPaper />
//             },
//             {
//                 title: "CI",
//                 path: '/mecanicacuantica/ci',
//                 icon: <IoIcons.IoIosPaper />
//             }
//         ]
//     },
//     {
//         title:'Nuestro Universo',
//         path: '/nuestrouniverso',
//         icon: <DiIcons.DiAtom />,
//     }
//     ]
//   )
// }