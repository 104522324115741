import React, { useEffect } from 'react'
import "../styles/Home.css"
import { Link } from 'react-router-dom'
import AOS from 'aos';
import 'aos/dist/aos.css';
import Navbar from '../components/navbar'
import Footers from '../components/footers';

function Home() {

    // useEffect(() =>{
    //   AOS.init({duration:1000})
    // }, [])

    useEffect(() =>{
      const screenWidth = window.innerWidth;
      if (screenWidth > 600) {
        AOS.init({ duration: 1000 });
      } else {
        AOS.init({ disable: true });
      }
    }, [])

  return (
    <>
    <Navbar />
    <div className="home">
      <a className='b-alpha'>
        <section className="sec-phrase">
          <div className="phrase">
            <blockquote>"La ciencia no conoce país, porque el conocimiento pertenece a la humanidad, y es la antorcha que ilumina al mundo".</blockquote>
            <h3>Louis Pasteur</h3>
          </div>
        </section>
      </a>

      <section className="pri">

        <section className='tbs'>

          <div className='title' data-aos='slide-right'>
            <a>Topics</a>
          </div>
          <div className='square-tbs'>

            <a href="/fisica" className='a-hb' data-aos='flip-left'>
              <div className='hb-text'>
                <div className='hb-title'>
                  <h1>Física</h1>
                </div>
                {/* <div className='hb-description'>
                  {/* <h3>What is the Higgs Boson? How do the particles have mass? 
                  The Higgs Boson also called the God's particle gives mass to the fundamental particles. 
                  Discovered in July 4 of 2012 in the LHC in the CERN.</h3> 
                </div> */}
              </div>
            </a>

            <a href='/programa' className='a-bh' data-aos='flip-up'>
              <div className='hb-text'>
                <div className='hb-title'>
                  <h1>Programación</h1>
                </div>
                {/* <div className='bh-description'>
                <h3>What's a black hole? A black hole is an astronomical object that in summary, 
                absorbs all around it (even light), only some particles can escape of it thorugh 
                the Hawking's radiation.</h3>
                </div> */}
              </div>
            </a>

            <a href='/mate' className='a-bb' data-aos='flip-right'>
              <div className='hb-text'>
                <div className='hb-title'>
                  <h1>Matemáticas</h1>
                </div>
                {/* <div className='bb-description'>
                  <h3>What was the Big Bang? What's the cosmic inflation? 
                  The Big Bang was the beginning of the universe, it was the moment when space and time was created, 
                  before it there was nothing.</h3>
                </div> */}
              </div>
            </a>

          </div>

        </section>

        <section className='steam' data-aos='slide-left'>
          <div className='square-steam'>
            <div className='steam-text'>
              <div className='steam-title'>
                <a><h1>EDUCACIÓN STEM</h1></a>
                <hr></hr>
              </div>
              <div className='steam-description'>
                <h3>The Big Science es una plataforma en línea que está revolucionando la educación STEM.
                    Ofrece lecciones atractivas de física, astronomía, matemáticas y programación.
                    The Big Science Project hace que el aprendizaje de las ciencias sea emocionante y accesible para todas las edades.
                    ¡Descubre las maravillas de la ciencia y la tecnología y alimenta tu curiosidad con The Big Science Project!</h3>
              </div>
            </div>
            <div className='steam-image'>
              <img src={require('../assets/images/8eaf9f19-2de9-4727-b68a-406b9382f4e1.png')}/>
            </div>
          </div>
        </section>
    
      </section>

      <section className="topics-more">
        <div className="section-bigscience" data-aos='fade-down'>
          <hr></hr>
          <h2>¿Por qué ser...?</h2>
          <hr></hr>
        </div>
        <div className="topics" data-aos='slide-right'>
          <a href="/cientifico" class="cientifico"><div className="bi by">
            <a class="prof"><h3>Científico</h3></a>
          </div></a>
          <a href="/informatico" class="tecnologo"><div className="bi by">
            <a class="prof"><h3>Informático</h3></a>
          </div></a>
          <a href="/ingeniero" class="ingeniero"><div className="bi by">
            <a className='prof'><h3>Ingeniero</h3></a>
          </div></a>
          {/* <a href="/artista" class="artista"><div className="bi by">
            <a className='prof'><h3>Artísta</h3></a>
          </div></a> */}
          <a href="/matematico" class="matematico"><div className="bi by">
            <a className='prof'><h3>Matemático</h3></a>
          </div></a>
        </div>
      </section>

    </div>
    <Footers />
    </>
  )
}

export default Home
