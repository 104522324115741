import React, { useEffect, useState } from 'react'
import Navbar from '../components/navbar'
import Footers from '../components/footers'
import '../styles/Politicas_de_privacidad.css'
export default function Privacidad() {
    const [isMobile, setIsMobile] = useState(window.innerWidth <=600);
    useEffect(()=> {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 600);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    });

    const styles = {
        bcgdsm:{
            padding: isMobile? '1.8rem':'4rem 25%'
        }
    }
  return (
    <>
        <Navbar />
        <section style={styles.bcgdsm} className='ptdpdd'>
            <section className='ptcpdd'>
                <h1 style={{textAlign:'center', fontSize:'1.8rem', marginBottom:'4rem'}}>Política de Privacidad</h1>
                <h3>Última actualización: 8 de septiembre del 2024</h3>
                <h2>En The Big Science, nos comprometemos a proteger su privacidad y garantizar la confidencialidad de 
                la información que pueda recopilarse durante su visita a nuestro sitio web. Esta Política de Privacidad 
                describe cómo recopilamos, usamos, y protegemos su información cuando navega en nuestra página dedicada 
                a la oferta de cursos autodidácticos en las áreas de Ciencia, Tecnología, Ingeniería y Matemáticas (STEM).</h2>
                
                <div className='pvd'>
                    <h1>1. Información Recopilada</h1>
                    <h2><span className='subtl'>1.1. Información No Personal:</span> El acceso al Sitio es libre y no requiere la creación de cuentas de usuario. Usted puede navegar por los cursos disponibles y acceder al contenido educativo proporcionado.</h2>
                </div>

                <div className='pvd'>
                    <h1>2. Uso de la Información</h1>
                    <h2><span className='subtl'>2.1. Mejora del Sitio:</span> Utilizamos la información recopilada para mejorar nuestro Sitio, analizar patrones de uso, y personalizar el contenido y los recursos que ofrecemos.</h2>
                </div>

                <div className='pvd'>
                    <h1>3. Compartir Información</h1>
                    <h2><span className='subtl'>3.1. Terceros:</span> No compartimos, vendemos ni alquilamos su información personal a terceros. Sin embargo, podemos compartir información no personal, como datos agregados sobre el uso del Sitio, con nuestros socios para ayudarnos a mejorar la oferta educativa.</h2>
                    <h2><span className='subtl'>3.2. Obligaciones Legales:</span> Podemos divulgar información cuando sea necesario para cumplir con la ley, responder a procesos legales, o proteger los derechos, la propiedad o la seguridad de The Big Science, nuestros usuarios u otros.</h2>
                </div>

                <div className='pvd'>
                    <h1>4. Seguridad de la Información</h1>
                    <h2><span className='subtl'>4.1. Medidas de Seguridad:</span> Tomamos medidas de seguridad razonables para proteger la información recopilada contra acceso no autorizado, alteración, divulgación o destrucción. Sin embargo, tenga en cuenta que ningún método de transmisión por Internet o almacenamiento electrónico es completamente seguro y no podemos garantizar la seguridad absoluta de su información.</h2>
                </div>

                <div className='pvd'>
                    <h1>5. Enlaces a Sitios de Terceros</h1>
                    <h2>Nuestro Sitio puede contener enlaces a otros sitios web que no están bajo nuestro control. No somos responsables de las prácticas de privacidad de estos sitios de terceros. Le recomendamos que revise las políticas de privacidad de cualquier sitio web que visite a través de enlaces desde nuestro Sitio.</h2>
                </div>

                <div className='pvd'>
                    <h1>6. Cambios a esta Política de Privacidad</h1>
                    <h2>Nos reservamos el derecho de modificar esta Política de Privacidad en cualquier momento. Las modificaciones serán efectivas inmediatamente después de su publicación en el Sitio. Le recomendamos que revise esta página periódicamente para estar al tanto de cualquier cambio.</h2>
                </div>

                <div className='pvd'>
                    <h1>7. Derechos del Usuarios</h1>
                    <h2><span className='subtl'>7.1. Acceso y Corrección:</span> Si desea acceder, corregir o eliminar cualquier información personal que hayamos podido recopilar, o tiene preguntas sobre esta Política de Privacidad, puede contactarnos a thebigscience0@gmail.com.</h2>
                    <h2><span className='subtl'>7.2. Optar por no Participar:</span> Si no desea recibir comunicaciones de nuestra parte, puede optar por no participar en cualquier momento siguiendo las instrucciones proporcionadas en nuestros correos electrónicos o contactándonos directamente.</h2>
                </div>

                <div className='pvd'>
                    <h1>8. Contacto</h1>
                    <h2>Si tiene alguna pregunta o inquietud sobre esta Política de Privacidad o nuestras prácticas de manejo de datos, puede contactarnos a thebigscience0@gmail.com.</h2>
                </div>
            </section>
        </section> 
        <Footers />
    </>
  )
}