import React, { useEffect, useState } from 'react'
import Navbar from '../../components/navbar'
import Footers from '../../components/footers'
import '../../styles/Intco.css'

export default function Intco() {
    const [isMobile, setIsMobile] = useState(window.innerWidth <=600);
    useEffect(()=> {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 600);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    });

    const styles = {
        bcgdsm:{
            padding: isMobile? '1.8rem':'2rem 25%'
        }
    }
  return (
    <>
      <Navbar/>
      <section style={styles.bcgdsm} className='bcgdsm'>
            <div className='titami'>
                <h1>¿Por qué ser un Informático?</h1>
                <h4>Esdras Hernández</h4>
                <h5>6 minutos de lectura</h5>
            </div>

            <div className='igsm'>
                <img src={require('../../assets/images/f87c81f9-3b8a-480b-b70a-fdedd3188633.jpg')}/>
            </div>

            <div className='dessm'>
                <h2>Según un reportaje de El Financiero (Hernández, 2018), aproximadamente el 80% de los jóvenes mexicanos
                 no están interesados en estudiar alguna rama de la ciencia, también en el mismo reportaje se detalla que solo el 7%
                 de los egresados de educación superior opta por una carrera STEM. Una de las principales causas de este problema que menciona dicho reportaje es la carencia de habilidades
                 en los jóvenes mexicanos de ciencia, tecnología y matemáticas.</h2>
                 <h2>Debido a todo esto, decidimos escribir este pequeño pero importante artículo hacia todas las personas con alguna aspiración por las STEM.</h2>
            </div>
            <div className='igsm'>
                <img src={require('../../assets/images/500392d3-0415-460e-a7c2-15d76cf1bc8e.png')} />
            </div>
            <div className='dessm'>
                <h1>¿Qué es ser un Informático?</h1>
                <h2>Un informático es un profesional especializado en el estudio, diseño, desarrollo, implementación y 
                gestión de sistemas de información, software y redes informáticas. Este campo abarca desde la programación 
                y el desarrollo de aplicaciones hasta la seguridad cibernética y la inteligencia artificial. Los 
                informáticos son fundamentales en la era digital, ya que crean y mantienen las tecnologías que conectan 
                al mundo.</h2>
                <h2>Los informáticos son el motor detrás de la revolución digital que ha transformado la forma en que
                 vivimos, trabajamos y nos comunicamos. Desde los algoritmos que permiten el funcionamiento de las redes 
                 sociales hasta los sistemas que protegen nuestra información personal, los informáticos desempeñan un 
                 papel crucial en la sociedad moderna. Sin su trabajo, gran parte de la tecnología que utilizamos 
                 diariamente simplemente no existiría.
                </h2>
                <h2>Los informáticos trabajan en una amplia gama de áreas, que incluyen:</h2>
                <ul>
                    <li>Desarrollo de Software: Creación de aplicaciones, programas y sistemas operativos que impulsan todo, desde teléfonos móviles hasta supercomputadoras.</li>
                    <li>Seguridad Cibernética: Protección de sistemas informáticos contra ataques, brechas de seguridad y amenazas cibernéticas.</li>
                    <li>Inteligencia Artificial: Desarrollo de sistemas que pueden aprender, razonar y tomar decisiones de manera autónoma.</li>
                    <li>Bases de Datos: Diseño y mantenimiento de sistemas que almacenan y organizan grandes cantidades de información.</li>
                </ul>
            </div>
            <div className='igsm'>
                <img src={require('../../assets/images/40874182-4fb0-49f5-bf97-ed4c73c8d905.png')}/>
            </div>
            <div className='dessm'>
                <h1>¿Por qué ser un Informático?</h1>
                <h2>Si te apasiona la tecnología, disfrutas resolviendo problemas complejos y te atrae la idea de estar en el centro de la 
                innovación digital, entonces la informática es el campo ideal para ti. Ser un informático te permite no solo desarrollar 
                soluciones tecnológicas, sino también tener un impacto significativo en casi todos los aspectos de la vida moderna. 
                La informática es una carrera dinámica y en constante evolución, lo que significa que siempre habrá algo nuevo que aprender 
                y explorar.
                </h2>
                <h2>Como dijo el pionero de la informática, Alan Turing, "Las máquinas solo pueden hacer lo que les decimos que hagan, pero lo
                 que les decimos que hagan es limitado solo por nuestra imaginación". Los informáticos son quienes están detrás de esas 
                 máquinas, empujando los límites de lo que es posible con la tecnología.</h2>
            </div>
            <div className='igsm'>
                <img src={require('../../assets/images/6242a2b9-7eca-4402-a679-349a5b9f49d3.png')} />
            </div>
            <div className='dessm'>
                <h1>¿El nuevo Alan Turing?</h1>
                <h2>México tiene un gran potencial en el campo de la informática, con muchos jóvenes talentosos que ya 
                están trabajando en desarrollos que podrían transformar la industria tecnológica a nivel global. Con el 
                apoyo y los recursos adecuados, estos jóvenes pueden convertirse en los próximos grandes innovadores que 
                lideren la próxima ola de avances digitales.
                </h2>
                <h2>En The Big Science soñamos con un México que sea líder en tecnología e innovación. Por eso, a cada uno de los lectores de este artículo que siente una pasión por la informática, les decimos: ¡Continúa!</h2>
                <h3>"Cuando la historia mire al siglo XX, verá la a la ciencia y la tecnología como su tema; encontrará en los monumentos de gran ciencia - grandes cohetes, aceleradores de alta energía, reactores de investigación de alto flujo - 
                símbolos de nuestro tiempo de la misma manera como seguramente encuentra en Norte Dame un símbolo de la Edad Media [...] 
                Construimos nuestros monumentos en el nombre de la verdad científica [...] 
                Nosotros usamos nuestra gran ciencia para sumar prestigio a nuestra nación [...]". </h3>
                <h3>Alvin Weinberg</h3>

            </div>

      </section>
      <Footers />
    </>
  )
}