import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import * as FaIcons from 'react-icons/fa';
import * as AiIcons from 'react-icons/ai';
import Sidebardata from './SidebarData'
import SubMenu from './SubMenu';
import { IconContext } from 'react-icons/lib';

const Nav = styled.div`
  background: #15171c; 
  height:80px;
  display:flex;
  justify-content:flex-start;
  align-items:center;
`;

const NavIcon = styled(Link)`
  margin-left:2rem;
  height:90px;
  font-size:2rem;
  display:flex;
  justify-content:flex-start;
  align-items:center;
`;

const SidebarNav = styled.nav`
  background:#15171c;
  width:250px;
  height:100vh;
  display:flex;
  justify-content:center;
  position:fixed;
  top:0;
  left: ${({sidebar}) => (sidebar ? '0': '-100%')};
  transition:350ms;
  z-index:10;
`;

const SidebarWrap = styled.div`
  width:100%;
`;
const Sidebar = () => {
  const [sidebar, setSidebar] = useState(false);

  const showSidebar = () => setSidebar(!sidebar)

  return (
    <div>
    <IconContext.Provider value={{color:'#fff'}}>
      <Nav>
        <NavIcon to='#'>
          <FaIcons.FaBars onClick={showSidebar} />
        </NavIcon>
      </Nav>
      <SidebarNav sidebar={sidebar}>
        <SidebarWrap>
          <NavIcon to='#'>
            <AiIcons.AiOutlineClose onClick={showSidebar} />
          </NavIcon>
          {Sidebardata.map((item, index) => {
            return <SubMenu item={item} key={index} />;
          })}
        </SidebarWrap>
      </SidebarNav>
      </IconContext.Provider>
    </div>
  )
}

export default Sidebar;