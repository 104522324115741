import React, { useEffect, useState } from 'react'
import Sidebar from '../../components/Sidebar'
import '../../styles/Mecanicacuantica.css'
import Footers from '../../components/footers';

export default function MecanicaCuantica() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <=600);
    useEffect(()=> {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 600);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    });

    const styles = {
        tpn:{
          padding:isMobile? '1rem': '1rem 10rem'
        },
        text:{
          fontSize: isMobile? '1.2rem': 'auto'
        },
        seetop:{
          fontSize: isMobile? '0.9rem':'auto'
        },
        seetop1:{
          margin: isMobile? '1rem':'2rem 15%'
        }
    }
  return (
    <>
        <Sidebar />
        <section className='tpcs'>
          <div className='mtpc'>
            <h1>Mecánica cuántica</h1>
            <hr />
          </div>
          <div style={styles.tpn} className='tpn'>

            <div className='tpo'>
              <a className='htp mco' href='/fisica/mecanica_cuantica/dualidad_onda_particula'>
                <h2 style={styles.text}>Dualidad Onda-Partícula</h2>
                <div style={styles.seetop1} className='seetop'>
                    <h1 style={styles.seetop}>En la naturaleza de las partículas subatómicas existe una propiedad llamada dualidad onda partícula 
                    en la que estos entes son ondas y partículas al mismo tiempo, sin embargo, en algunos casos específicos 
                    solo se comportan como una onda o una partícula.</h1>
                </div>
              </a>
            </div>

            <div className='tpt'>
              <a className='htp mcs' href='/fisica/mecanica_cuantica/principio_incertidumbre'>
                <h2 style={styles.text}>Principio de Incertidumbre de Heisenberg</h2>
                <div style={styles.seetop1}  className='seetop'>
                  <h1 style={styles.seetop}>La naturaleza intrínseca de las partículas subatómicas tiene una propiedad llamada: indeterminación. Esta indeterminación es la que nos frena de desarrollar una teoría completa de la mecánica cuántica.</h1>
                </div>
              </a>
            </div>

          </div>
        </section>
        <Footers />
    </>
  )
}