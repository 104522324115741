import React, { useEffect, useState } from 'react'
import Navbar from '../../components/navbar'
import Footers from '../../components/footers'
import '../../styles/Ctfo.css'

export default function Ctfo() {
    const [isMobile, setIsMobile] = useState(window.innerWidth <=600);
    useEffect(()=> {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 600);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    });

    const styles = {
        bcgdsm:{
            padding: isMobile? '1.8rem':'2rem 25%'
        }
    }
  return (
    <>
        <Navbar/>
        <section style={styles.bcgdsm} className='bcgdsm'>

            <div className='titami'>
                <h1>¿Por qué ser un Científico?</h1>
                <h4>Esdras Hernández</h4>
                <h5>6 minutos de lectura</h5>
            </div>

            <div className='igsm'>
                <img src={require('../../assets/images/Scientist.jpeg')}/>
            </div>

            <div className='dessm'>
                <h2>Según un reportaje de El Financiero (Hernández, 2018), aproximadamente el 80% de los jóvenes mexicanos
                 no están interesados en estudiar alguna rama de la ciencia, también en el mismo reportaje se detalla que solo el 7%
                 de los egresados de educación superior opta por una carrera STEM. Una de las principales causas de este problema que menciona dicho reportaje es la carencia de habilidades
                 en los jóvenes mexicanos de ciencia, tecnología y matemáticas.</h2>
                 <h2>Debido a todo esto, decidimos escribir este pequeño pero importante artículo hacia todas las personas con alguna aspiración por las STEM.</h2>
            </div>
            <div className='igsm'>
                <img src={require('../../assets/images/daf036e6-b965-4801-8ed4-036e92f1adaa.jpg')} />
            </div>
            <div className='dessm'>
                <h1>¿Qué es ser un científico?</h1>
                <h2>Un científico es profesional que se dedica al estudio de las ramas de la ciencia con el objetivo de 
                aumentar y expandir el conocimiento, así como mejorar la vida del ser humano. Esto es posible gracias 
                a la formulación de hipótesis y la comprobación de las mismas, es decir, el método científico.</h2>
                <h2>¿Por qué son importantes los científico? Un científico no es solamente una persona dedicada meramente
                 al estudio, sino que son el motor del progreso, cada ciencia creada ha contribuido a lo largo de la historia 
                 al ser humano, su entorno y su sociedad. Sin los avances científicos, muchos de los logros tecnológicos y médicos que damos por sentados hoy en día no existirían.</h2>
                <h2>Los científicos trabajan en una amplia variedad de campos, desde la biología y la química hasta la física y la astronomía. Su trabajo puede involucrar:</h2>
                <ul>
                    <li>Realizar experimentos para probar teorías e hipótesis.</li>
                    <li>Desarrollar nuevas tecnologías o métodos de investigación.</li>
                    <li>Publicar sus hallazgos en revistas científicas.</li>
                    <li>Colaborar con otros científicos y profesionales.</li>
                    <li>Aplicar sus conocimientos para resolver problemas prácticos.</li>
                </ul>
            </div>
            <div className='igsm'>
                <img src={require('../../assets/images/cfe7a0c3-e18b-44d0-b733-6e2cbcfe322a.jpg')}/>
            </div>
            <div className='dessm'>
                <h1>¿Por qué ser un científico?</h1>
                <h2>Si eres una persona que constantemente está buscando aprender, que se pregunta el por qué de cada objeto o situación,
                si tiene una pasión por el universo, por los animales, naturaleza, etc. Entonces, estas apunto de convertirte en un científico.
                </h2>
                <h2>Albert Einstein, en una ocasión resumió en una breve frase lo más importante que debe de tener un científico, en sus palabras: 
                "La imaginación es más importante que el conocimiento. El conocimiento es limitado y la imaginación circunda el mundo".</h2>
                <h2>Una cosa tienen clara los científicos, lo mejor y más motivador de la ciencia y el conocimiento, es que siempre habrá algo nuevo que aprender cada día y, 
                que nadie sabe cuál es el siguiente gran paso y quién lo dará. Esto es una de las principales motivaciones por las que muchos jóvenes científicos suben al barco de la ciencia
                con el sueño de ser quien dara el siguiente gran paso y, de esta manera su nombre quedará grabado perpetuamente en el libro de la ciencia.</h2>
            </div>
            <div className='igsm'>
                <img src={require('../../assets/images/001ed482-ba55-4bc9-b0bf-32c55d0edbf0.png')} />
            </div>
            <div className='dessm'>
                <h1>¿Una (Gran) Ciencia Mexicana?</h1>
                <h2>Si bien México es un país que aún le falta desarrollarse para alcanzar la etapa de Gran Ciencia, lo cierto es que nuestro país, mucha de nuestros jóvenes
                    tienen el deseo de ser reconocidos mundialmente, tienen el mismo deseo que tuvieron los científicos del siglo XX,
                    tienen la capacidad y habilidad de crear cualquier instrumento, artefacto, máquina, etc. Lo único que la sociedad mexicana tiene que hacer es apoyarlos.
                </h2>
                <h2>En The Big Science soñamos que el día llegue, que México sea una potencia en ciencia. Es por esta misma razón que a cada uno de los lectores que está leyendo este pequeño artículo
                y tienen un deseo entusiasta por la ciencia, les decimos: Continua.</h2>
                <h3>"Cuando la historia mire al siglo XX, verá la a la ciencia y la tecnología como su tema; encontrará en los monumentos de gran ciencia - grandes cohetes, aceleradores de alta energía, reactores de investigación de alto flujo - 
                símbolos de nuestro tiempo de la misma manera como seguramente encuentra en Norte Dame un símbolo de la Edad Media [...] 
                Construimos nuestros monumentos en el nombre de la verdad científica [...] 
                Nosotros usamos nuestra gran ciencia para sumar prestigio a nuestra nación [...]". </h3>
                <h3>Alvin Weinberg</h3>

            </div>

        </section>
        <Footers />
    </>
  )
}