import React, { useEffect } from 'react'
import Navbar from '../components/navbar'
import Footers from '../components/footers'
import '../styles/About.css'
import AOS from 'aos'
function Aboutme() {
  // useEffect(() =>{
  //   AOS.init({duration:1000})
  // }, [])

  useEffect(() =>{
    const screenWidth = window.innerWidth;
    if (screenWidth > 600) {
      AOS.init({ duration: 1000 });
    } else {
      AOS.init({ disable: true });
    }
  }, [])
  return (
    <>
    <Navbar />
    <section className='flabm'>

      <div className='prabm' data-aos='fade-up'>
        <div className='prabmtxt'>
          <h2>Acerca de The Big Science</h2>
          <h3>The Big Science es un proyecto STEAM que tiene como objetivo impactar la vida de los jóvenes a través de la ciencia y la tecnología con el fin de revivir la chispa de la Gran Ciencia. El sitio web de The Big Science cuenta con información acerca de física, programación y matemáticas para que los jóvenes nunca dejen de aprender y sigan explorando el mundo de la ciencia.</h3>
        </div>
        <div className='prabmig'>
          <img src={require('../assets/images/1.png')} />
        </div>
      </div>

      <div className='pabd' data-aos='flip-down'>
        <div className='pabdsp'>
          <h2>"Cuando la historia mire al siglo XX, verá la a la ciencia y la tecnología como su tema; encontrará en los monumentos de gran ciencia - grandes cohetes, aceleradores de alta energía, reactores de investigación de alto flujo - símbolos de nuestro tiempo de la misma manera como seguramente encuentra en Norte Dame un símbolo de la Edad Media [...] Construimos nuestros monumentos en el nombre de la verdad científica [...] Nosotros usamos nuestra gran ciencia para sumar prestigio a nuestra nación [...]". </h2>
          <h3>Alvin Weinberg</h3>
        </div>
      </div>

      <div className='nuvi' data-aos='slide-up'>
        <div className='desnuvi'>
          <h2>The Big Science tiene como objetivo impactar en la vida de los jóvenes mexicanos a través de la ciencia y tecnología.
          Con nuestros cursos y sitio web buscamos llevar una educación STEM gratuita y de calidad a todos los rincones de la 
          República Mexicana con el fin de que las nuevas generaciones se interesen por el progreso científico y que a futuro sean los próximos líderes de la Gran Ciencia Mexicana.</h2>
        </div>
        <div className='titnuvi'>
          <h2>Nuestra misión</h2>
        </div>
      </div>

      <div className='imso' data-aos='zoom-in-down'>
        <div className='deim'>
          <h2>Impacto social</h2>
          <h3>En The Big Science ofrecemos cursos gratuitos de ciencia (física, astrónomía y mecánica cuántica), tecnología (Python y Desarrollo Web) y matemáticas Cursos de para estudiantes que ingresan a secundaria, preparatoria.</h3>
          <h3>También ofrecemos pláticas acerca de temas interesantes de la ciencia y tecnología.</h3>
        </div>
        <div className='imsoig'>
          <img src={require('../assets/images/Impacto.png')} />
        </div>
      </div>
    </section>
    <Footers />
    </>
  )
}

export default Aboutme
