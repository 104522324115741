import React, { useEffect, useState } from 'react'
import Navbar from '../components/navbar'
import Footers from '../components/footers'
import '../styles/Terms_and_conditions.css'
export default function Terms_and_conditions() {
    const [isMobile, setIsMobile] = useState(window.innerWidth <=600);
    useEffect(()=> {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 600);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    });

    const styles = {
        bcgdsm:{
            padding: isMobile? '1.8rem':'4rem 25%'
        }
    }
  return (
    <>
        <Navbar />
        <section style={styles.bcgdsm} className='tmscns'>
            <section className='tmscnds1'>
                <h1 style={{textAlign:'center', fontSize:'1.8rem', marginBottom:'4rem'}}>Términos y Condiciones</h1>
                <h3>Última actualización: 9 de septiembre del 2024</h3>
                <h2>Estos Términos y Condiciones ("Términos") rigen el acceso y uso de la página web The Big Science 
                ("Sitio"), dedicada a la oferta de cursos autodidácticos en las áreas de Ciencia, Tecnología, Ingeniería y
                Matemáticas (STEM). Al acceder o utilizar nuestro Sitio, usted acepta estos Términos. Si no está de acuerdo
                con ellos, no debe utilizar el Sitio.</h2>
                
                <div className='tmscd'>
                    <h1>1. Uso del Sitio</h1>
                    <h2><span className='subtl'>1.1. Acceso y Navegación:</span> El acceso al Sitio es libre y no requiere la creación de cuentas de usuario. Usted puede navegar por los cursos disponibles y acceder al contenido educativo proporcionado.</h2>
                    <h2><span className='subtl'>1.2. Uso Aceptable:</span> Usted se compromete a utilizar el Sitio de manera responsable y en conformidad con todas las leyes aplicables. Está prohibido utilizar el Sitio para cualquier actividad ilegal, fraudulenta o no autorizada.</h2>
                </div>

                <div className='tmscd'>
                    <h1>2. Propiedad Intelectual</h1>
                    <h2><span className='subtl'>2.1. Contenido del Sitio:</span> Todos los materiales, incluyendo textos, imágenes, videos, gráficos, y otros contenidos disponibles en el Sitio, son propiedad de The Big Science o de sus respectivos titulares de derechos. Queda estrictamente prohibido copiar, distribuir, modificar o reproducir cualquier contenido sin el permiso expreso por escrito del propietario.</h2>
                    <h2><span className='subtl'>2.2. Uso Personal:</span> Los materiales disponibles en el Sitio son para uso personal y no comercial. No se permite el uso del contenido para fines comerciales sin autorización previa.</h2>
                </div>

                <div className='tmscd'>
                    <h1>3. Limitación de Responsabilidad</h1>
                    <h2><span className='subtl'>3.1. Exactitud del Contenido:</span> The Big Science se esfuerza por asegurar que la información y el contenido proporcionado en el Sitio sean precisos y estén actualizados. Sin embargo, no garantizamos la exactitud, integridad o actualidad del contenido.</h2>
                    <h2><span className='subtl'>3.2. Exclusión de Responsabilidad:</span> The Big Science no será responsable por ningún daño directo, indirecto, incidental, especial o consecuente que resulte del uso o la imposibilidad de uso del Sitio, incluidos, pero no limitados a, daños por pérdida de datos o beneficios, o interrupciones del servicio.</h2>
                </div>

                <div className='tmscd'>
                    <h1>4. Modificaciones al Sitio y a los Términos</h1>
                    <h2><span className='subtl'>4.1. Cambios en el Sitio:</span> The Big Science se reserva el derecho de modificar, suspender o descontinuar, temporal o permanentemente, cualquier parte del Sitio con o sin previo aviso.</h2>
                    <h2><span className='subtl'>4.2. Revisión de Términos:</span> Nos reservamos el derecho de modificar estos Términos en cualquier momento. Las modificaciones entrarán en vigor inmediatamente después de su publicación en el Sitio. Su uso continuado del Sitio después de la publicación de cualquier cambio constituye su aceptación de dichos cambios.</h2>
                </div>

                <div className='tmscd'>
                    <h1>5. Enlaces a Terceros</h1>
                    <h2>El Sitio puede contener enlaces a otros sitios web operados por terceros. Estos enlaces son proporcionados para su conveniencia y no implican ningún respaldo por parte de The Big Science. No somos responsables por el contenido o las prácticas de privacidad de estos sitios de terceros.</h2>
                </div>

                <div className='tmscd'>
                    <h1>6. Privacidad</h1>
                    <h2>The Big Science respeta su privacidad. No recolectamos información personal que pueda identificarlo directamente. Consulte nuestra Política de Privacidad para obtener más detalles sobre cómo manejamos cualquier información que pueda ser recolectada de forma pasiva.</h2>
                </div>

                <div className='tmscd'>
                    <h1>7. Jurisdicción y Ley Aplicable</h1>
                    <h2>Estos Términos se regirán e interpretarán de acuerdo con las leyes de México, sin tener en cuenta sus principios sobre conflictos de leyes. Usted acepta someterse a la jurisdicción exclusiva de los tribunales de México.</h2>
                </div>

                <div className='tmscd'>
                    <h1>8. Contacto</h1>
                    <h2>Si tiene alguna pregunta sobre estos Términos, por favor, contáctenos a thebigscience0@gmail.com.</h2>
                </div>
            </section>
        </section>
        <Footers />
    </>
  )
}