import './App.css';
import {Route, Routes, BrowserRouter as Router} from 'react-router-dom'
import Home from './pages/Home'
// import Navbar from './components/navbar'
// import News from './pages/News'
import About from './pages/Aboutme'
import Contacts from './pages/Contacts'
// import Footer from './components/footer'
import Fisica from './pages/Fisica';
import Programacion from './pages/Programacion';
import Mate from './pages/Mate';
import Relativity from './pages/Physics/Relativity';
import MecanicaCuantica from './pages/Physics/MecanicaCuantica';
import Python from './pages/Programming/Python';
import Algebra from './pages/Matematicas/Algebra';
import Ctfo from './pages/STEM/Ctfo';
import Inero from './pages/STEM/Inero';
import Intco from './pages/STEM/Intco';
import Matco from './pages/STEM/Matco';
import Posrel from './pages/Physics/Relatividad/Posrel';
import Simurela from './pages/Physics/Relatividad/Simurela';
import Duaparon from './pages/Physics/Quantum/Duaparon';
import Prinhein from './pages/Physics/Quantum/Prinhein';
import Eculin from './pages/Matematicas/Algebra/Eculin';
import Sisecu from './pages/Matematicas/Algebra/Sisecu';
import Instherr from './pages/Programming/Python/Instherr';
import Varlist from './pages/Programming/Python/Varlist';
import Terms_and_conditions from './pages/Terms_and_conditions';
import Privacidad from './pages/Privacidad';
function App() {
  return (
    <div className="App">
      <Router>
      {/* <Navbar /> */}
      <Routes>
        <Route path='/' exact element={<Home />} />
        <Route path='/about' exact element={<About />}/>
        <Route path='/contact' exact element={<Contacts />}/>
        <Route path="/fisica" exact element={<Fisica />}/>
        <Route path='/programa' exact element={<Programacion />} />
        <Route path='/mate' exact element={<Mate />} />
        <Route path='/fisica/relatividad' exact element={<Relativity />} />
        <Route path='/fisica/mecanicacuantica' exact element={<MecanicaCuantica />} />
        <Route path='/programa/python' exact element={<Python />} />
        <Route path='/mate/algebra' exact element={<Algebra />} />
        <Route path='/cientifico' exact element={<Ctfo />} />
        <Route path='/informatico' exact element={<Intco />} />
        <Route path='/ingeniero' exact element={<Inero />} />
        <Route path='/matematico' exact element={<Matco />} />
        <Route path='/fisica/relatividad/postulados' exact element={<Posrel />} />
        <Route path='/fisica/relatividad/simultaneidad_relatividad' exact element={<Simurela />} />
        <Route path='/fisica/mecanica_cuantica/dualidad_onda_particula' exact element={<Duaparon />} />
        <Route path='/fisica/mecanica_cuantica/principio_incertidumbre' exact element={<Prinhein />} />
        <Route path='/mate/algebra/ecuaciones_lineales' exact element={<Eculin />} />
        <Route path='/mate/algebra/sistemas_ecuaciones' exact element={<Sisecu />} />
        <Route path='/programa/python/instalacion_herramientas' exact element={<Instherr />} />
        <Route path='/programa/python/variables_listas' exact element={<Varlist />} />
        <Route path='/terminos_y_condiciones' exact element={<Terms_and_conditions />} />
        <Route path='/politicas_de_privacidad' exact element={<Privacidad/>} />
      </Routes>
      {/* <Footer /> */}
      </Router>
    </div>
  );
}

export default App;
