import React, { useEffect, useState } from 'react'
import Sidebar from '../../../components/Sidebar'
import '../../../styles/Var.css'
import Footers from '../../../components/footers';

export default function Varlist() {
    const [isMobile, setIsMobile] = useState(window.innerWidth <=600);
    useEffect(()=> {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 600);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    });

    const styles = {
        bcgdsm:{
            padding: isMobile? '1.8rem':'2rem 25%'
        }
    }
  return (
    <>
    <Sidebar />
    <section style={styles.bcgdsm} className='bcgdsm'>

            <div className='titami'>
                <h1>Variables</h1>
                <h4>Esdras Hernández</h4>
                <h5>6 minutos de lectura</h5>
            </div>

            <div className='igsm'>
                <img src={require('../../../assets/images/913ff545-43a2-4d2a-bab1-2897b611808c.jpg')}/>
            </div>

            <div className='dessm'>
                <h1>¿Qué es una variable?</h1>
                <h2>Una variable es una caja para guardar un valor, es decir, imagínate que tienes una caja que y dentro
                 guardas un peluche tuyo, esto mismo es una variable porque cumple con la definición simple que es una 
                 caja en donde puedas guardar un valor, en este caso tu caja es tu variable (ósea dónde guardas tu valor) 
                 y el peluche es el valor, entonces caja = peluche.</h2>
                 <h2>En cualquier lenguaje de programación, una variable es fundamental porque es aquí donde guardas 
                 desde simples números hasta bases de datos o incluso una Inteligencia Artificial.</h2>
            </div>
            <div className='igsm'>
                <img src={require('../../../assets/images/feee83d2-1684-4e94-abd3-69dfcba8c029.png')} />
            </div>
            <div className='dessm'>
                <h2>Hay varios tipos de datos en python, entre los más populares, están:</h2>
                <ul>
                    <li>Datos de tipo integers: números enteros (pueden ser negativos o positivos).</li>
                    <li>Datos de tipo floats: números decimales.</li>
                    <li>Datos de tipo strings: caracteres del lenguaje ordinario, en otras palabras, lenguaje humano (lo veremos más adelante).</li>
                    <li>Datos booleanos: este tipo de variable solo tiene dos valores: True y False.</li>
                    <li>Listas: aquí se pueden almacenar muchos datos desde números hasta strings.</li>
                </ul>

                <h2>Estos son solo algunos tipos de datos y todos estos se guardan en una variable. Ahora, ¿cómo se escriben las variables en Python?</h2>
                <h2>Para escribir variables de tipo integers y floats (numéricas):</h2>
                <div className='codes' style={{fontFamily:'monospace', backgroundColor:'#2b2727', borderRadius:'0.5rem'}}>
                    <span style={{color:'#fff', fontSize:'1rem'}}>
                        <span style={{color:'#454', fontFamily:'monospace'}}>x</span> <span style={{color:'#c0c2c4', fontFamily:'monospace'}}>=</span> <span style={{color:'#19385e', fontFamily:'monospace'}}>12</span><span style={{color:'#454', fontFamily:'monospace'}}>  # Integer</span><br />
                        <span style={{color:'#454', fontFamily:'monospace'}}>y</span> <span style={{color:'#c0c2c4', fontFamily:'monospace'}}>=</span> <span style={{color:'#19385e', fontFamily:'monospace'}}>-24</span><br />
                        <span style={{color:'#454', fontFamily:'monospace'}}>r</span> <span style={{color:'#c0c2c4', fontFamily:'monospace'}}>=</span>  <span style={{color:'#19385e', fontFamily:'monospace'}}>3.1415</span><span style={{color:'#454', fontFamily:'monospace'}}>  # Float</span><br />
                        <span style={{color:'#454', fontFamily:'monospace'}}>t</span> <span style={{color:'#c0c2c4', fontFamily:'monospace'}}>=</span> <span style={{color:'#19385e', fontFamily:'monospace'}}>0.34456</span><br />
                        <span style={{color:'#454', fontFamily:'monospace'}}>u</span> <span style={{color:'#c0c2c4', fontFamily:'monospace'}}>=</span> <span style={{color:'#19385e', fontFamily:'monospace'}}>1/3</span><span style={{color:'#454', fontFamily:'monospace'}}>  # Fraction</span><br />
                    </span>
                </div>

                <h2>Para poder visualizarlas en la terminal:</h2>
                <div className='codes' style={{fontFamily:'monospace', backgroundColor:'#2b2727', borderRadius:'0.5rem'}}>
                    <span style={{color:'#fff', fontSize:'1rem'}}>
                        <span style={{color:'#bacc70', fontFamily:'monospace'}}>print(<span style={{color:'#454', fontFamily:'monospace'}}>x</span>)</span> <br />
                        <span style={{color:'#bacc70', fontFamily:'monospace'}}>print(<span style={{color:'#454', fontFamily:'monospace'}}>y</span>)</span> <br />
                        <span style={{color:'#bacc70', fontFamily:'monospace'}}>print(<span style={{color:'#454', fontFamily:'monospace'}}>r</span>)</span> <br />
                        <span style={{color:'#bacc70', fontFamily:'monospace'}}>print(<span style={{color:'#454', fontFamily:'monospace'}}>t</span>)</span> <br />
                        <span style={{color:'#bacc70', fontFamily:'monospace'}}>print(<span style={{color:'#454', fontFamily:'monospace'}}>u</span>)</span> <br />
                    </span>
                </div>
                <h2>Y ejecuta el programa (haz click en el ícono de triángulo en la parte superior derecha) en la terminal de Visual Studio Code.</h2>

                <h2>Para escribir variables de tipo strings:</h2>
                <div className='codes' style={{fontFamily:'monospace', backgroundColor:'#2b2727', borderRadius:'0.5rem'}}>
                    <span style={{color:'#fff', fontSize:'1rem'}}>
                        <span style={{color:'#454', fontFamily:'monospace'}}>nombre</span> <span style={{color:'#c0c2c4', fontFamily:'monospace'}}>=</span> <span style={{color:'#c28753', fontFamily:'monospace'}}>'Hello World!'</span><br />
                        <span style={{color:'#454', fontFamily:'monospace'}}>text</span> <span style={{color:'#c0c2c4', fontFamily:'monospace'}}>=</span> <span style={{color:'#c28753', fontFamily:'monospace'}}>'Hi!'</span><br />
                        <span style={{color:'#454', fontFamily:'monospace'}}>animal</span> <span style={{color:'#c0c2c4', fontFamily:'monospace'}}>=</span>  <span style={{color:'#c28753', fontFamily:'monospace'}}>'Lion'</span><br />
                    </span>
                </div>
                <h2>Y ejecuta el programa (haz click en el ícono de triángulo en la parte superior derecha) en la terminal de Visual Studio Code.</h2>
                <h2>Las variables de tipo string se utilizan para escribir caracteres humanos debido a que estos no son
                 comprendidos por la computadora, es por esta razón que se escriben con comillas doble (“”) o simples (‘’).</h2>
                <h2>Para poder visualizarlas en la terminal:</h2>
                <div className='codes' style={{fontFamily:'monospace', backgroundColor:'#2b2727', borderRadius:'0.5rem'}}>
                    <span style={{color:'#fff', fontSize:'1rem'}}>
                        <span style={{color:'#bacc70', fontFamily:'monospace'}}>print(<span style={{color:'#454', fontFamily:'monospace'}}>nombre</span>)</span> <br />
                        <span style={{color:'#bacc70', fontFamily:'monospace'}}>print(<span style={{color:'#454', fontFamily:'monospace'}}>text</span>)</span> <br />
                        <span style={{color:'#bacc70', fontFamily:'monospace'}}>print(<span style={{color:'#454', fontFamily:'monospace'}}>animal</span>)</span> <br />
                    </span>
                </div>

                <h2>Para escribir variables de tipo booleanas:</h2>
                <div className='codes' style={{fontFamily:'monospace', backgroundColor:'#2b2727', borderRadius:'0.5rem'}}>
                    <span style={{color:'#fff', fontSize:'1rem'}}>
                        <span style={{color:'#454', fontFamily:'monospace'}}>verdadero</span> <span style={{color:'#c0c2c4', fontFamily:'monospace'}}>=</span> <span style={{color:'#5ca8e6', fontFamily:'monospace'}}>True</span><br />
                        <span style={{color:'#454', fontFamily:'monospace'}}>falso</span> <span style={{color:'#c0c2c4', fontFamily:'monospace'}}>=</span> <span style={{color:'#5ca8e6', fontFamily:'monospace'}}>False</span><br />
                    </span>
                </div>
                <h2>Para poder visualizarlas en la terminal:</h2>
                <div className='codes' style={{fontFamily:'monospace', backgroundColor:'#2b2727', borderRadius:'0.5rem'}}>
                    <span style={{color:'#fff', fontSize:'1rem'}}>
                        <span style={{color:'#bacc70', fontFamily:'monospace'}}>print(<span style={{color:'#454', fontFamily:'monospace'}}>verdadero</span>)</span> <br />
                        <span style={{color:'#bacc70', fontFamily:'monospace'}}>print(<span style={{color:'#454', fontFamily:'monospace'}}>falso</span>)</span> <br />
                    </span>
                </div>

                <h2>Ahora, para escribir nombres de variables, hay algunas reglas:</h2>
                <ul>
                    <li>No deben comenzar con números.</li>
                    <li>Si una variable se nombra con más de una palabra, se debe separar con un “_”.</li>
                </ul>
                <h2>Para visualizar esto:</h2>
                <div className='codes' style={{fontFamily:'monospace', backgroundColor:'#2b2727', borderRadius:'0.5rem'}}>
                    <span style={{color:'#fff', fontSize:'1rem'}}>
                        <span style={{color:'#454', fontFamily:'monospace'}}>3t</span> <span style={{color:'#c0c2c4', fontFamily:'monospace'}}>=</span> <span style={{color:'#19385e', fontFamily:'monospace'}}>45</span><span style={{color:'#454', fontFamily:'monospace'}}>  # Esto no es correcto</span><br />
                        <span style={{color:'#454', fontFamily:'monospace'}}>t3</span> <span style={{color:'#c0c2c4', fontFamily:'monospace'}}>=</span> <span style={{color:'#19385e', fontFamily:'monospace'}}>45</span><span style={{color:'#454', fontFamily:'monospace'}}>  # Esto es correcto</span><br />
                    </span>
                </div>
                <div className='codes' style={{fontFamily:'monospace', backgroundColor:'#2b2727', borderRadius:'0.5rem'}}>
                    <span style={{color:'#fff', fontSize:'1rem'}}>
                        <span style={{color:'#454', fontFamily:'monospace'}}>variable uno</span> <span style={{color:'#c0c2c4', fontFamily:'monospace'}}>=</span> <span style={{color:'#c28753', fontFamily:'monospace'}}>"Hello World!"</span><span style={{color:'#454', fontFamily:'monospace'}}>  # Esto no es correcto</span><br />
                        <span style={{color:'#454', fontFamily:'monospace'}}>variable_uno</span> <span style={{color:'#c0c2c4', fontFamily:'monospace'}}>=</span> <span style={{color:'#c28753', fontFamily:'monospace'}}>"Hi!"</span><span style={{color:'#454', fontFamily:'monospace'}}>  # Esto es correcto</span><br />
                    </span>
                </div>
                <h2>Te preguntarás qué significa el “#”, esto se conoce como comentario que sirve para hacer anotaciones en el código como recordatorios o una breve descripción acerca del funcionamiento del código. Sin embargo, el comentario no afecta el funcionamiento del código.</h2>
            </div>

    </section>
    <Footers />
    </>
  )
}