import React, { useEffect, useState } from 'react'
import Navbar from '../../components/navbar'
import Footers from '../../components/footers'
import '../../styles/Matco.css'

export default function Matco() {
    const [isMobile, setIsMobile] = useState(window.innerWidth <=600);
    useEffect(()=> {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 600);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    });

    const styles = {
        bcgdsm:{
            padding: isMobile? '1.8rem':'2rem 25%'
        }
    }
  return (
    <>
      <Navbar/>
      <section style={styles.bcgdsm} className='bcgdsm'>
            <div className='titami'>
                <h1>¿Por qué ser un Matemático?</h1>
                <h4>Esdras Hernández</h4>
                <h5>6 minutos de lectura</h5>
            </div>

            <div className='igsm'>
                <img src={require('../../assets/images/6efff990-1314-4a1e-9cd0-c00802b581ed.jpg')}/>
            </div>

            <div className='dessm'>
                <h2>Según un reportaje de El Financiero (Hernández, 2018), aproximadamente el 80% de los jóvenes mexicanos
                 no están interesados en estudiar alguna rama de la ciencia, también en el mismo reportaje se detalla que solo el 7%
                 de los egresados de educación superior opta por una carrera STEM. Una de las principales causas de este problema que menciona dicho reportaje es la carencia de habilidades
                 en los jóvenes mexicanos de ciencia, tecnología y matemáticas.</h2>
                 <h2>Debido a todo esto, decidimos escribir este pequeño pero importante artículo hacia todas las personas con alguna aspiración por las STEM.</h2>
            </div>
            <div className='igsm'>
                <img src={require('../../assets/images/263ea453-493c-44c9-a28c-27f7f99c2867.png')} />
            </div>
            <div className='dessm'>
                <h1>¿Qué es ser un Matemático?</h1>
                <h2>Un matemático es un profesional que se dedica al estudio de las matemáticas, una disciplina que 
                abarca desde la teoría de números y el álgebra hasta la geometría, el cálculo y la estadística. Los 
                matemáticos se enfocan en comprender patrones, resolver problemas abstractos y formular teorías que 
                pueden aplicarse en una amplia variedad de campos, desde la física y la ingeniería hasta la economía 
                y la informática.</h2>
                <h2>Los matemáticos son fundamentales para el progreso de la ciencia y la tecnología. Sus descubrimientos 
                y teorías proporcionan las herramientas necesarias para resolver problemas complejos en diversas 
                disciplinas. Las matemáticas están en la base de muchas innovaciones tecnológicas, desde la criptografía 
                que protege nuestras comunicaciones hasta los modelos matemáticos que predicen el clima. Sin los 
                matemáticos, gran parte del conocimiento y las aplicaciones que impulsan el mundo moderno no existirían.</h2>
                <h2>Los matemáticos trabajan en una amplia gama de áreas, que incluyen:</h2>
                <ul>
                    <li>Matemáticas Puras: Investigación teórica que busca comprender los fundamentos de las matemáticas, como la teoría de números y el álgebra abstracta.</li>
                    <li>Matemáticas Aplicadas: Uso de las matemáticas para resolver problemas prácticos en campos como la física, la biología, la economía y la ingeniería.</li>
                    <li>Estadística: Análisis de datos para extraer conclusiones y hacer predicciones en áreas como la investigación médica, la economía y las ciencias sociales.</li>
                    <li>Criptografía: Desarrollo de métodos matemáticos para proteger la información y las comunicaciones digitales.</li>
                    <li>Modelado Matemático: Creación de modelos que simulan fenómenos del mundo real, como el crecimiento poblacional o el cambio climático.</li>
                </ul>
            </div>
            <div className='igsm'>
                <img src={require('../../assets/images/c4a18712-204e-4a15-ab13-c1679b750bdb.png')}/>
            </div>
            <div className='dessm'>
                <h1>¿Por qué ser un Matemático?</h1>
                <h2>Si te fascinan los números, los patrones, y disfrutas desentrañando problemas complejos, 
                entonces la matemática es el campo ideal para ti. Ser matemático no solo te permitirá explorar 
                las profundidades de una disciplina que ha intrigado a la humanidad durante milenios, sino que 
                también te dará las herramientas para contribuir a avances en una multitud de áreas. La belleza de las 
                matemáticas radica en su capacidad para explicar el mundo de manera precisa y elegante.
                </h2>
                <h2>El matemático Paul Erdős dijo una vez: "Las matemáticas no son solo otra ciencia; son la clave para 
                todas las demás ciencias". Esta afirmación captura la esencia de la importancia de las matemáticas: son 
                el lenguaje universal que subyace en todo el conocimiento científico.</h2>
            </div>
            <div className='igsm'>
                <img src={require('../../assets/images/da343399-9366-44b4-b551-6b89cc4dbf67.png')} />
            </div>
            <div className='dessm'>
                <h1>¿El nuevo erudito?</h1>
                <h2>México tiene un rico legado de talentos matemáticos y un futuro prometedor en este campo. Muchos 
                jóvenes mexicanos tienen la capacidad y la pasión para convertirse en matemáticos de renombre, capaces 
                de realizar descubrimientos que podrían tener un impacto global. Con el apoyo adecuado, estos jóvenes 
                podrían ser los que formulen las próximas grandes teorías matemáticas que revolucionen el mundo.
                </h2>
                <h2>En The Big Science soñamos con un México que sea una potencia en matemáticas y ciencias. Por eso, a cada uno de los lectores de este artículo que siente una pasión por los números y los desafíos intelectuales, les decimos: ¡Continúa!</h2>
                <h3>"Cuando la historia mire al siglo XX, verá la a la ciencia y la tecnología como su tema; encontrará en los monumentos de gran ciencia - grandes cohetes, aceleradores de alta energía, reactores de investigación de alto flujo - 
                símbolos de nuestro tiempo de la misma manera como seguramente encuentra en Norte Dame un símbolo de la Edad Media [...] 
                Construimos nuestros monumentos en el nombre de la verdad científica [...] 
                Nosotros usamos nuestra gran ciencia para sumar prestigio a nuestra nación [...]". </h3>
                <h3>Alvin Weinberg</h3>

            </div>

      </section>
      <Footers />
    </>
  )
}