import React, { useEffect, useState } from 'react'
import Navbar from '../../components/navbar'
import Footers from '../../components/footers'
import '../../styles/Inero.css'
export default function Inero() {
    const [isMobile, setIsMobile] = useState(window.innerWidth <=600);
    useEffect(()=> {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 600);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    });

    const styles = {
        bcgdsm:{
            padding: isMobile? '1.8rem':'2rem 25%'
        }
    }
  return (
    <>
      <Navbar/>
      <section style={styles.bcgdsm} className='bcgdsm'>
            <div className='titami'>
                <h1>¿Por qué ser un Ingeniero?</h1>
                <h4>Esdras Hernández</h4>
                <h5>4 minutos de lectura</h5>
            </div>

            <div className='igsm'>
                <img src={require('../../assets/images/5b77eb1d-0769-49f0-8b32-fa61367c7a9d.jpg')}/>
            </div>

            <div className='dessm'>
                <h2>Según un reportaje de El Financiero (Hernández, 2018), aproximadamente el 80% de los jóvenes mexicanos
                 no están interesados en estudiar alguna rama de la ciencia, también en el mismo reportaje se detalla que solo el 7%
                 de los egresados de educación superior opta por una carrera STEM. Una de las principales causas de este problema que menciona dicho reportaje es la carencia de habilidades
                 en los jóvenes mexicanos de ciencia, tecnología y matemáticas.</h2>
                <h2>Debido a todo esto, decidimos escribir este pequeño pero importante artículo hacia todas las personas con alguna aspiración por las STEM.</h2>
            </div>
            <div className='igsm'>
                <img src={require('../../assets/images/a2242ce8-f9b3-49cd-b4ae-fccf610da071.png')} />
            </div>
            <div className='dessm'>
                <h1>¿Qué es ser un Ingeniero?</h1>
                <h2>Un ingeniero es un profesional dedicado a la aplicación práctica del conocimiento científico y 
                matemático para diseñar, construir y optimizar estructuras, máquinas, sistemas y procesos que mejoren 
                la vida del ser humano. Mientras que los científicos buscan comprender el mundo, los ingenieros utilizan 
                ese conocimiento para crear soluciones tangibles que resuelvan problemas y mejoren la calidad de vida.</h2>
            </div>
            <div className='igsm'>
                <img src={require('../../assets/images/bea99678-2313-49b3-81f2-c7b516047626.jpg')}/>
            </div>
            <div className='dessm'>
                <h1>¿Por qué ser un Ingeniero?</h1>
                <h2>Los ingenieros son los artífices del progreso material y tecnológico en nuestra sociedad. Desde la 
                construcción de puentes y edificios hasta el desarrollo de tecnologías avanzadas como los microchips y 
                las energías renovables, el impacto de la ingeniería se ve en cada aspecto de nuestra vida diaria. Sin 
                los ingenieros, muchos de los avances en infraestructura, tecnología y sostenibilidad que damos por 
                sentados hoy en día no existirían.</h2>
                <h2>Los ingenieros trabajan en una amplia gama de campos, que incluyen:</h2>
                <ul>
                    <li>Ingeniería Civil: Diseño y construcción de infraestructuras como puentes, carreteras y edificios.</li>
                    <li>Ingeniería Eléctrica: Diseño y mantenimiento de sistemas eléctricos, desde redes de energía hasta dispositivos electrónicos.</li>
                    <li>Ingeniería Química: Transformación de materias primas en productos útiles mediante procesos químicos.</li>
                    <li>Ingeniería Informática: Creación de software, hardware y sistemas de información que impulsan la tecnología moderna.</li>
                </ul>
            </div>
            <div className='igsm'>
                <img src={require('../../assets/images/414b560b-1464-4e03-89d1-b19a52010ec6.jpg')} />
            </div>
            <div className='dessm'>
                <h1>¿El próximo inventor?</h1>
                <h2>Aunque México aún tiene un camino por recorrer para alcanzar su máximo potencial en ingeniería y 
                tecnología, es innegable que muchos de nuestros jóvenes tienen el talento y la pasión necesarios para 
                convertirse en líderes en estos campos. Con el apoyo adecuado, estos jóvenes pueden ser los creadores 
                de la próxima gran innovación que impulse no solo a México, sino al mundo entero.
                </h2>
                <h2>En The Big Science soñamos con un México que sea una potencia en ingeniería y tecnología. 
                Por eso, a cada uno de los lectores de este pequeño pero importante artículo que siente un entusiasmo por la 
                ingeniería, les decimos: ¡Continúa!</h2>
                <h3>"Cuando la historia mire al siglo XX, verá la a la ciencia y la tecnología como su tema; encontrará en los monumentos de gran ciencia - grandes cohetes, aceleradores de alta energía, reactores de investigación de alto flujo - 
                símbolos de nuestro tiempo de la misma manera como seguramente encuentra en Norte Dame un símbolo de la Edad Media [...] 
                Construimos nuestros monumentos en el nombre de la verdad científica [...] 
                Nosotros usamos nuestra gran ciencia para sumar prestigio a nuestra nación [...]". </h3>
                <h3>Alvin Weinberg</h3>

            </div>

        </section>
        <Footers />

    </>
  )
}