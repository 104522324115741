import React, {useEffect, useState} from 'react'
import '../styles/Sidebar.css'
import Sidebar from '../components/Sidebar'
import VideoFisica from '../assets/Video_Fisica.mp4'
import '../styles/Topics_Fisica.css'
import Footers from '../components/footers';

import AOS from 'aos'
export default function Fisica() {
  // useEffect(() => {
  //   AOS.init({duration:1000});
  // }, []);

  useEffect(() =>{
    const screenWidth = window.innerWidth;
    if (screenWidth > 600) {
      AOS.init({ duration: 1000 });
    } else {
      AOS.init({ disable: true });
    }
  }, [])

  const [isMobile, setIsMobile] = useState(window.innerWidth <=1014);
    useEffect(()=> {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 1014);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    });

    const styles = {
        pse:{
            margin: isMobile? '2rem':'5rem 12rem'
        },
        prse:{
          display:'grid',
          gridTemplateColumns: isMobile? 'repeat(auto-fit, minmax(1, 1fr))': 'repeat(auto-fit, minmax(2, 1fr))',
          gridTemplateRows: isMobile? 'repeat(auto-fit, minmax(2, 1fr))':'minmax(100px, auto)'
        },
        blh:{
          gridRow: isMobile? '2':'1',
          gridColumn: isMobile? '1': '1',
          margin: isMobile? '2rem': '4rem'
        },
        imgprse:{
          gridColumn: isMobile? '1':'2',
          gridRow: isMobile? '1':'1',
          width: isMobile? '100%': 'auto',
          display: isMobile? 'flex':'auto', // Use flexbox in the parent container
          justifyContent: isMobile? 'center':'auto', // Center the image in the container
          alignItems: isMobile? 'center':'auto', // Center it vertically if needed
          padding: isMobile ? '2rem' : '0rem'
        },
        img:{
          width: isMobile? '80%': 'auto',
          // width: window.innerWidth < 600 ? '80%' : (isMobile ? '60%' : 'auto'),
          margin: isMobile? '0 auto': 'auto', // Center the image horizontally
          display: isMobile? 'block':'auto' 
        },
        gridboxtops:{
          display:'grid',
          gridTemplateColumns: isMobile? 'repeat(auto-fit, minmax(1, 1fr))': 'repeat(auto-fit, minmax(2, 1fr))',
          gridTemplateRows: isMobile? 'repeat(auto-fit, minmax(4, 1fr))':'minmax(100px, auto)'
        },
        meca:{
          gridColumn: isMobile? '1':'1',
          gridRow: isMobile? '1':'1',
        },
        relat:{
          gridColumn: isMobile? '1':'2',
          gridRow: isMobile? '2':'1',
        },
        mecac:{
          gridColumn: isMobile? '1':'1',
          gridRow: isMobile? '3':'2',
        },
        nuuni:{
          gridColumn: isMobile? '1':'2',
          gridRow: isMobile? '4':'2',
        },
        descriptions:{
          // margin: isMobile? '-33rem 0': '-32rem 0',
          // margin: window.innerWidth < 600 ? '-35rem 0': '-32rem 0',
          margin: window.innerWidth < 600 ? '-35rem 0' : (isMobile ? '-33rem 0' : '-32rem 0')
        },
    }
  return (
    <>
      <Sidebar />
      <section className='grid'>
        <div className='bg'>
          <div className="bg-f">
            <video src={VideoFisica} autoPlay loop muted />

            <div className="title-f">
              <h1 style={{color:'#b0aeae'}}>Física</h1>
            </div>
          </div>
        </div>

        <div style={styles.pse} className='pse' data-aos='flip-up'>
          <div style={styles.prse} className='prse'>
            <div style={styles.blh} className='blh'>
              <blockquote>Me parece haber sido sólo un niño jugando en la orilla del mar, divirtiéndose y buscando una piedra más lisa o una concha más bonita de lo normal, mientras el gran océano de la verdad yacía ante mis ojos con todo por descubrir.</blockquote>
              <h3>Isaac Newton</h3>
            </div>
            <div style={styles.imgprse} className='img-prse'>
              <img style={styles.img} src={require('../assets/images/Isaac_Newton.jpg')} />
            </div>
          </div>
        </div>
        
        <div style={styles.gridboxtops} className='grid-box-tops'>
          {/* <div className='top'>
            <div className='top-box'> */}
              <a style={styles.meca} href='#' className='top meca'>
                <div className='names'><h2>Mecánica clásica</h2></div>
                <div style={styles.descriptions} className='descriptions'>{/*<h2>La mecánica clásica es la rama de la física que estudia las leyes
                del comportamiento de los cuerpos macroscópicos en reposo y a velocidades pequeñas comparadas 
                con la velocidad de la luz.</h2>*/}
                <h2>Próximamente</h2></div>
              </a>
            {/* </div>
          </div> */}

          {/* <div className='top'>
            <div className='top-box'> */}
              <a style={styles.relat} href='/fisica/relatividad' className='top relat'>
                <div className='names'><h2>Relatividad</h2></div>
                <div style={styles.descriptions} className='descriptions'><h2>La Teoría de la Relatividad fue publicada en los años 1905 
                (relatividad especial) y 1915 (relatividad general) por Albert Einstein. Esta teoría nos habla 
                acerca de las leyes de los cuerpos en marcos de referencia distinta y la naturaleza de la gravedad.</h2></div>
              </a>
            {/* </div>
          </div> */}

          {/* <div className='top'>
            <div className='top-box'> */}
              <a style={styles.mecac} href='/fisica/mecanicacuantica' className='top mecac' >
                <div className='names'><h2>Mecánica cuántica</h2></div>
                <div style={styles.descriptions} className='descriptions'><h2>La Mecánica Cuántica nace con la Radiación del Cuerpo Negro por
                Max Planck en 1905, descubrió que los fotones pequeños paquetes de energía llamados "quanta".
                La Mecánica cuántica estudia la naturaleza de los sistemas subatómicos.</h2></div>
              </a>
            {/* </div>
          </div> */}

          {/* <div className='top'>
            <div className='top-box'> */}
              <a style={styles.nuuni} href='#' className='top nuuni'>
                <div className='names'><h2>Nuestro Universo</h2></div>
                <div style={styles.descriptions} className='descriptions'>{/*<h2>La Teoría de la Relatividad fue publicada en los años 1905 
                (relatividad especial) y 1915 (relatividad general) por Albert Einstein. Esta teoría nos habla 
                acerca de las leyes de los cuerpos en marcos de referencia distinta y la naturaleza de la gravedad.</h2>*/}<h2>Próximamente</h2></div>
              </a>
            {/* </div>
          </div> */}
        </div>
          {/* <a href="#" className='one-top mec box-title'>
            <div className='name'><h2>Mecánica clásica</h2></div>
            <div className='description'><h2>La mecánica clásica es la rama de la física que estudia las leyes
            del comportamiento de los cuerpos macroscópicos en reposo y a velocidades pequeñas comparadas 
            con la velocidad de la luz.</h2></div>
          </a>

          <a href="#" className='two-top mecc box-title'>
            <div className='name'><h2>Mecánica cuántica</h2></div>
            <div className='description'><h2>La Mecánica Cuántica nace con la Radiación del Cuerpo Negro por
            Max Planck en 1905, descubrió que los fotones pequeños paquetes de energía llamados "quanta".
            La Mecánica cuántica estudia la naturaleza de los sistemas subatómicos.</h2></div>
          </a>

          <a href="#" className='three-top rela box-title'>
            <div className='name'><h2>Relatividad</h2></div>
            <div className='description'><h2>La Teoría de la Relatividad fue publicada en los años 1905 
            (relatividad especial) y 1915 (relatividad general) por Albert Einstein. Esta teoría nos habla 
            acerca de las leyes de los cuerpos en marcos de referencia distinta y la naturaleza de la gravedad.</h2></div>
          </a>

          <a href="#" className='four-top uni box-title'>
            <div className='name'><h2>Nuestro Universo</h2></div>
            <div className='description'><h2>La Teoría de la Relatividad fue publicada en los años 1905 
            (relatividad especial) y 1915 (relatividad general) por Albert Einstein. Esta teoría nos habla 
            acerca de las leyes de los cuerpos en marcos de referencia distinta y la naturaleza de la gravedad.</h2></div>
          </a> */}
      </section> 
      <Footers />
    </>
  )
}