import React, {useEffect, useState} from 'react';
import Sidebar from '../../../components/Sidebar';
import Footers from '../../../components/footers';
import equationsKatex from '../../../components/equations';
import '../../../styles/Sisecu.css';
export default function Sisecu() {
    const [result, setResult] =useState(null);

    const solveEquation = (result) => {
        setResult(result);
    };
    const equation1 = `
\\left\\lbrace
  \\begin{array}{l}
    2x + y = 1 \\\\
    3x + 4y = 14
  \\end{array}
\\right.
`;  

    const equation2 = '\{2x+y=1}';
    const equation3 = '\{y=1-2x}';
    const equation4 = '\{3x+4y=14}';
    const equation5 = '\{3x + 4(1-2x) = 14}';
    const equation6 = '\{3x + 4 -8x = 14}';
    const equation7 = '\{-5x = 10}';
    const equation8 = '\{x = \\frac{10}{-5}}';
    const equation9 = '\{x=-2}';
    const equation10 = '\{y=1-2(-2)}';
    const equation11 = '\{y = 5}';

    const equation12 = `
\\left\\lbrace
  \\begin{array}{l}
    3x + 2y = 14 \\\\
    x - 2y = 2
  \\end{array}
\\right.
`;  

    const equation13 = '\{4x = 16}';
    const equation14 = '\{x = \\frac{16}{4}}';
    const equation15 = '\{x = 4}';
    const equation16 = '\{x - 2y=2}';
    const equation17 = '\{4-2y=2}';
    const equation18 = '\{-2y=-2}';
    const equation19 = '\{y=\\frac{-2}{-2}}';
    const equation20 = '\{y=1}';
    
    const equation21 = `
\\left\\lbrace
  \\begin{array}{l}
    3x-2y=0 \\\\
    2x + y =7
  \\end{array}
\\right.
`;  
    const equation22 = '\{3x-2y=0}';
    const equation23 = '\{3x = 2y}';
    const equation24 = '\{\\frac{3x}{2}=y}';
    const equation25 = '\{2x + y =7}';
    const equation26 = '\{y = 7-2x}';

    const equation27 = '\{\\frac{3x}{2}= 7-2x}';
    const equation28 = '\{3x= 2(7-2x)}';
    const equation29 = '\{3x = 14 -4x}';
    const equation30 = '\{7x = 14}';
    const equation31 = '\{x = \\frac{14}{7}}';
    const equation32 = '\{x=2}';

    const equation33 = '\{2x + y =7}';
    const equation34 = '\{2(2) + y =7}';
    const equation35 = '\{4 + y =7}';
    const equation36 = '\{y=3}';

    const equation37 = `
\\left\\lbrace
  \\begin{array}{l}
    3x + y =1 \\\\
    5x + 2y = 1
  \\end{array}
\\right.
`;  
    const equation38 = '\{3x + y =1}';
    const equation39 = '\{y= 1-3x}';

    const equation40 = '\{5x + 2y = 1}';
    const equation41 = '\{5x + 2(1-3x) = 1}';
    const equation42 = '\{5x + 2 -6x = 1}';
    const equation43 = '\{-x + 2 = 1}';
    const equation44 = '\{-x=-1}';
    const equation45 = '\{x=1}';

    const equation46 = '\{y= 1-3x}';
    const equation47 = '\{y= 1-3(1)}';
    const equation48 = '\{y= -2}';

    const equation49 = `
    \\left\\lbrace
    \\begin{array}{l}
        2x + 5y = 15 \\\\
        4x + y = 21
    \\end{array}
    \\right.
    `; 
    const equation51 = `
    \\left\\lbrace
    \\begin{array}{l}
        (2x + 5y = 15)(-2) \\\\
        4x + y = 21
    \\end{array}
    \\right.
    `;
    const equation52 = `
    \\left\\lbrace
    \\begin{array}{l}
        -4x -10y = -30 \\\\
        4x + y = 21
    \\end{array}
    \\right.
    `;
    
    const equation53 = '\{0 - 9y = -9}';
    const equation54 = '\{y=\\frac{-9}{-9}}';
    const equation55 = '\{y=1}';

    const equation56 = '\{2x + 5y = 15}';
    const equation57 = '\{2x + 5(1) = 15}';
    const equation58 = '\{2x = 10}';
    const equation59 = '\{x=\\frac{10}{2}}';
    const equation60 = '\{x=5}';


    const equation61 = `
    \\left\\lbrace
    \\begin{array}{l}
        x-y = 1 \\\\
        4x +3y=18
    \\end{array}
    \\right.
    `;
    const equation62 = '\{x=3 \\space y=2}';

    const equation63 = `
    \\left\\lbrace
    \\begin{array}{l}
        x-y=2 \\\\
        2x + 3y=9
    \\end{array}
    \\right.
    `;

    const equation64 = '\{x=3 \\space y=1}';

    const equation65 = `
    \\left\\lbrace
    \\begin{array}{l}
        2x + y=7 \\\\
        x+2y=2
    \\end{array}
    \\right.
    `;

    const equation66 = '\{x=4 \\space y=-1}';

    const equation67 = `
    \\left\\lbrace
    \\begin{array}{l}
        4x -3y=11 \\\\
        8x + 4y=12
    \\end{array}
    \\right.
    `;

    const equation68 = '\{x=2 \\space y=-1}';

    const equation69 = `
    \\left\\lbrace
    \\begin{array}{l}
        3x + 4y=10 \\\\
        x-4y= -2
    \\end{array}
    \\right.
    `;

    const equation70 = '\{x=2 \\space y=1}';

    const equation71 = `
    \\left\\lbrace
    \\begin{array}{l}
        x+2y=5 \\\\
        2x + 3y=8
    \\end{array}
    \\right.
    `;

    const equation72 = '\{x=1 \\space y=2}';

    const equation73 = `
    \\left\\lbrace
    \\begin{array}{l}
        3x + 5y=15 \\\\
        2x-3y=-9
    \\end{array}
    \\right.
    `;

    const equation74 = '\{x=0 \\space y=3}';

    const equation75 = `
    \\left\\lbrace
    \\begin{array}{l}
        5x + 2y = 11 \\\\
        2x -3y =12
    \\end{array}
    \\right.
    `;

    const equation76 = '\{x=3 \\space y=-2}';


    const rendered_Equation = equationsKatex(equation1);
    const rendered_Equation2 = equationsKatex(equation2);
    const rendered_Equation3 = equationsKatex(equation3);
    const rendered_Equation4 = equationsKatex(equation4);
    const rendered_Equation5 = equationsKatex(equation5);
    const rendered_Equation6 = equationsKatex(equation6);
    const rendered_Equation7 = equationsKatex(equation7);
    const rendered_Equation8 = equationsKatex(equation8);
    const rendered_Equation9 = equationsKatex(equation9);
    const rendered_Equation10 = equationsKatex(equation10);
    const rendered_Equation11 = equationsKatex(equation11);
    const rendered_Equation12 = equationsKatex(equation12);
    const rendered_Equation13 = equationsKatex(equation13);
    const rendered_Equation14 = equationsKatex(equation14);
    const rendered_Equation15 = equationsKatex(equation15);
    const rendered_Equation16 = equationsKatex(equation16);
    const rendered_Equation17 = equationsKatex(equation17);
    const rendered_Equation18 = equationsKatex(equation18);
    const rendered_Equation19 = equationsKatex(equation19);
    const rendered_Equation20 = equationsKatex(equation20);
    const rendered_Equation21 = equationsKatex(equation21);
    const rendered_Equation22 = equationsKatex(equation22);
    const rendered_Equation23 = equationsKatex(equation23);
    const rendered_Equation24 = equationsKatex(equation24);
    const rendered_Equation25 = equationsKatex(equation25);
    const rendered_Equation26 = equationsKatex(equation26);
    const rendered_Equation27 = equationsKatex(equation27);
    const rendered_Equation28 = equationsKatex(equation28);
    const rendered_Equation29 = equationsKatex(equation29);
    const rendered_Equation30 = equationsKatex(equation30);
    const rendered_Equation31 = equationsKatex(equation31);
    const rendered_Equation32 = equationsKatex(equation32);
    const rendered_Equation33 = equationsKatex(equation33);
    const rendered_Equation34 = equationsKatex(equation34);
    const rendered_Equation35 = equationsKatex(equation35);
    const rendered_Equation36 = equationsKatex(equation36);
    const rendered_Equation37 = equationsKatex(equation37);
    const rendered_Equation38 = equationsKatex(equation38);
    const rendered_Equation39 = equationsKatex(equation39);
    const rendered_Equation40 = equationsKatex(equation40);
    const rendered_Equation41 = equationsKatex(equation41);
    const rendered_Equation42 = equationsKatex(equation42);
    const rendered_Equation43 = equationsKatex(equation43);
    const rendered_Equation44 = equationsKatex(equation44);
    const rendered_Equation45 = equationsKatex(equation45);
    const rendered_Equation46 = equationsKatex(equation46);
    const rendered_Equation47 = equationsKatex(equation47);
    const rendered_Equation48 = equationsKatex(equation48);
    const rendered_Equation49 = equationsKatex(equation49);
    const rendered_Equation51 = equationsKatex(equation51);
    const rendered_Equation52 = equationsKatex(equation52);
    const rendered_Equation53 = equationsKatex(equation53);
    const rendered_Equation54 = equationsKatex(equation54);
    const rendered_Equation55 = equationsKatex(equation55);
    const rendered_Equation56 = equationsKatex(equation56);
    const rendered_Equation57 = equationsKatex(equation57);
    const rendered_Equation58 = equationsKatex(equation58);
    const rendered_Equation59 = equationsKatex(equation59);
    const rendered_Equation60 = equationsKatex(equation60);
    const rendered_Equation61 = equationsKatex(equation61);
    const rendered_Equation62 = equationsKatex(equation62);
    const rendered_Equation63 = equationsKatex(equation63);
    const rendered_Equation64 = equationsKatex(equation64);
    const rendered_Equation65 = equationsKatex(equation65);
    const rendered_Equation66 = equationsKatex(equation66);
    const rendered_Equation67 = equationsKatex(equation67);
    const rendered_Equation68 = equationsKatex(equation68);
    const rendered_Equation69 = equationsKatex(equation69);
    const rendered_Equation70 = equationsKatex(equation70);
    const rendered_Equation71 = equationsKatex(equation71);
    const rendered_Equation72 = equationsKatex(equation72);
    const rendered_Equation73 = equationsKatex(equation73);
    const rendered_Equation74 = equationsKatex(equation74);
    const rendered_Equation75 = equationsKatex(equation75);
    const rendered_Equation76 = equationsKatex(equation76);

    const [isMobile, setIsMobile] = useState(window.innerWidth <=600);
    useEffect(()=> {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 600);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    });

    const styles = {
        bcgdsm:{
            padding: isMobile? '1.8rem':'2rem 25%'
        }
    }

  return (
    <>
    <Sidebar />
    <section style={styles.bcgdsm} className='bcgdsm'>

            <div className='titami'>
                <h1>Sistemas de Ecuaciones Lineales</h1>
                <h4>Esdras Hernández</h4>
                <h5>10 minutos de lectura</h5>
            </div>

            <div className='igsm'>
                <img src={require('../../../assets/images/bf39b1dd-97b7-4ba8-8e6a-32fb782cf9ff.jpg')}/>
            </div>

            <div className='dessm'>
                <h2>Un sistema de ecuaciones son ecuaciones con las mismas incógnitas que describen un sistema o 
                situación. Una solución de un sistema es asignar valores a las incógnitas que hace verdadera a todas 
                las ecuaciones del sistema. Resolver un sistema significa hallar todas las soluciones del sistema.</h2>
                <h2>En este caso nos centraremos en las ecuaciones lineales.</h2>
            </div>
            <div className='igsm'>
                <img src={require('../../../assets/images/6ac7b049-59dd-42ca-b97e-6c335b11309b.jpg')} />
            </div>
            <div className='dessm'>
                <h2>Para resolver sistemas de ecuaciones existen varios métodos:</h2>
                <ul>
                    <li>Método de sustitución.</li>
                    <li>Método por eliminación.</li>
                    <li>Método de igualación.</li>
                </ul>
                <h2>El método de sustitución. Comenzamos con una ecuación en el sistema y despejamos una incógnita en términos de la otra incógnita.</h2>
                <ul>
                    <li>Paso 1. Despejar una incógnita: escoja una ecuación y despeja una incógnita en términos de otra.</li>
                    <li>Paso 2. Sustituir: sustituye en la incógnita de la segunda ecuación la ecuación hallada en el primer paso. Ahora, despeja la incógnita.</li>
                    <li>Paso 3. Hallar la segunda incógnita: con la solución hallada en el paso 2, sustituye el valor de la incógnita en la ecuación del paso 1 para despejar la ecuación restante.</li>
                </ul>
                <h2>Ejemplo 1</h2>
                <div className='equation'
                    dangerouslySetInnerHTML={{ __html: rendered_Equation }}
                /> 
                <h2>Paso 1:</h2>
                <div className='equation'
                    dangerouslySetInnerHTML={{ __html: rendered_Equation2 }}
                /> 

                <div className='equation'
                    dangerouslySetInnerHTML={{ __html: rendered_Equation3 }}
                /> 
                <h2>Paso 2:</h2>
                <div className='equation'
                    dangerouslySetInnerHTML={{ __html: rendered_Equation4 }}
                /> 
                <div className='equation'
                    dangerouslySetInnerHTML={{ __html: rendered_Equation5 }}
                /> 
                <div className='equation'
                    dangerouslySetInnerHTML={{ __html: rendered_Equation6 }}
                /> 
                <div className='equation'
                    dangerouslySetInnerHTML={{ __html: rendered_Equation7 }}
                /> 
                <div className='equation'
                    dangerouslySetInnerHTML={{ __html: rendered_Equation8 }}
                /> 
                <div className='equation'
                    dangerouslySetInnerHTML={{ __html: rendered_Equation9 }}
                /> 
                <h2>Paso 3:</h2>
                <div className='equation'
                    dangerouslySetInnerHTML={{ __html: rendered_Equation3 }}
                /> 
                <div className='equation'
                    dangerouslySetInnerHTML={{ __html: rendered_Equation10 }}
                /> 
                <div className='equation'
                    dangerouslySetInnerHTML={{ __html: rendered_Equation11 }}
                /> 
                <h2>Entonces, x=-2 y y=5. Esto quiere decir que en la coordenada (-2,5) las dos rectas de las ecuaciones 
                cruzan por ese punto.</h2>
                <h2>En el método por eliminación, combinamos las ecuaciones usando sumas o restas usando una de las incógnitas.</h2>
                <ul>
                    <li>Paso 1. Ajustar los coeficientes: multiplica una o más de las ecuaciones por un número apropiado 
                    de tal manera que el coeficiente de una incógnita sea negativo y el coeficiente de la misma incógnita 
                    de la segunda ecuación sea positivo, pero el coeficiente debe ser el mismo número.</li>
                    <li>Paso 2. Sumar las ecuaciones: suma las dos ecuaciones para eliminar una incógnita y, a 
                    continuación, despeja la incógnita restante.</li>
                    <li>Paso 3. Sustituir a la inversa: en una de las ecuaciones originales, sustituye el valor hallado 
                    en el paso 2 y despeja la incógnita restante.</li>
                </ul>
                <h2>Ejemplo 2:</h2>
                <div className='equation'
                    dangerouslySetInnerHTML={{ __html: rendered_Equation12 }}
                /> 
                <h2>Paso 1 y 2:</h2>
                <div className='equation'
                    dangerouslySetInnerHTML={{ __html: rendered_Equation12 }}
                /> 
                <div className='equation'
                    dangerouslySetInnerHTML={{ __html: rendered_Equation13 }}
                /> 
                <div className='equation'
                    dangerouslySetInnerHTML={{ __html: rendered_Equation14 }}
                /> 
                <div className='equation'
                    dangerouslySetInnerHTML={{ __html: rendered_Equation15 }}
                /> 
                <h2>Paso 3:</h2>
                <div className='equation'
                    dangerouslySetInnerHTML={{ __html: rendered_Equation16 }}
                />
                <div className='equation'
                    dangerouslySetInnerHTML={{ __html: rendered_Equation17 }}
                />
                <div className='equation'
                    dangerouslySetInnerHTML={{ __html: rendered_Equation18 }}
                />
                <div className='equation'
                    dangerouslySetInnerHTML={{ __html: rendered_Equation19 }}
                />
                <div className='equation'
                    dangerouslySetInnerHTML={{ __html: rendered_Equation20 }}
                />
                <h2>Entonces, x=4 y y=1. Esto quiere decir que en la coordenada (4,1) las dos rectas de las ecuaciones cruzan por ese punto.</h2>
                <h2>En el método de igualación despejamos la misma incógnita en ambas ecuaciones y las igualamos.</h2>
                <ul>
                    <li>Paso 1. Despejar incógnitas: despeja la misma incógnita en ambas ecuaciones.</li>
                    <li>Paso 2. Igualación: ahora las dos ecuaciones del paso uno igualarlas y resolver para la incógnita.</li>
                    <li>Paso 3. Sustituir: con el resultado del paso dos, sustituye el resultado en una de las dos ecuaciones originales y resuelve para la incógnita restante.</li>
                </ul>

                <h2>Ejemplo 3:</h2>
                <div className='equation'
                    dangerouslySetInnerHTML={{ __html: rendered_Equation21 }}
                />
                <h2>Paso 1:</h2>
                <div className='equation'
                    dangerouslySetInnerHTML={{ __html: rendered_Equation22 }}
                />
                <div className='equation'
                    dangerouslySetInnerHTML={{ __html: rendered_Equation23 }}
                />
                <div className='equation'
                    dangerouslySetInnerHTML={{ __html: rendered_Equation24 }}
                />
                <div className='equation'
                    dangerouslySetInnerHTML={{ __html: rendered_Equation25 }}
                />
                <div className='equation'
                    dangerouslySetInnerHTML={{ __html: rendered_Equation26 }}
                />
                <h2>Paso 2:</h2>
                <div className='equation'
                    dangerouslySetInnerHTML={{ __html: rendered_Equation27 }}
                />
                <div className='equation'
                    dangerouslySetInnerHTML={{ __html: rendered_Equation28 }}
                />
                <div className='equation'
                    dangerouslySetInnerHTML={{ __html: rendered_Equation29 }}
                />
                <div className='equation'
                    dangerouslySetInnerHTML={{ __html: rendered_Equation30 }}
                />
                <div className='equation'
                    dangerouslySetInnerHTML={{ __html: rendered_Equation31 }}
                />
                <div className='equation'
                    dangerouslySetInnerHTML={{ __html: rendered_Equation32 }}
                />
                <h2>Paso 3:</h2>
                <div className='equation'
                    dangerouslySetInnerHTML={{ __html: rendered_Equation33 }}
                />
                <div className='equation'
                    dangerouslySetInnerHTML={{ __html: rendered_Equation34 }}
                />
                <div className='equation'
                    dangerouslySetInnerHTML={{ __html: rendered_Equation35 }}
                />
                <div className='equation'
                    dangerouslySetInnerHTML={{ __html: rendered_Equation36 }}
                />
                <h2>Entonces, x=2 y y=3. Esto quiere decir que en la coordenada (2,3) las dos rectas de las ecuaciones cruzan por ese punto.</h2>
                <h2>Hagamos un par de ejemplos más:</h2>
                <div className='equation'
                    dangerouslySetInnerHTML={{ __html: rendered_Equation37 }}
                />
                <h2>Para resolver este sistema de ecuaciones lo podemos hacer a través de cualquier método, 
                en este caso escogeremos el método de sustitución.</h2>
                <h2>Paso 1:</h2>
                <div className='equation'
                    dangerouslySetInnerHTML={{ __html: rendered_Equation38 }}
                />
                <div className='equation'
                    dangerouslySetInnerHTML={{ __html: rendered_Equation39 }}
                />
                <h2>Paso 2:</h2>
                <div className='equation'
                    dangerouslySetInnerHTML={{ __html: rendered_Equation40 }}
                />
                <div className='equation'
                    dangerouslySetInnerHTML={{ __html: rendered_Equation41 }}
                />
                <div className='equation'
                    dangerouslySetInnerHTML={{ __html: rendered_Equation42 }}
                />
                <div className='equation'
                    dangerouslySetInnerHTML={{ __html: rendered_Equation43 }}
                />
                <div className='equation'
                    dangerouslySetInnerHTML={{ __html: rendered_Equation44 }}
                />
                <div className='equation'
                    dangerouslySetInnerHTML={{ __html: rendered_Equation45 }}
                />
                <h2>Paso 3:</h2>
                <div className='equation'
                    dangerouslySetInnerHTML={{ __html: rendered_Equation46 }}
                />
                <div className='equation'
                    dangerouslySetInnerHTML={{ __html: rendered_Equation47 }}
                />
                <div className='equation'
                    dangerouslySetInnerHTML={{ __html: rendered_Equation48 }}
                />
                <h2>El resultado entonces es x= 1 y y=-2.</h2>
                <h2>El segundo problema:</h2>
                <div className='equation'
                    dangerouslySetInnerHTML={{ __html: rendered_Equation49 }}
                />
                <h2>Para resolver este sistema de ecuaciones lo podemos hacer a través de cualquier método, 
                en este caso escogeremos el método de suma y resta.</h2>
                <h2>Paso 1:</h2>
                <div className='equation' style={{margin:'2rem 0'}}
                    dangerouslySetInnerHTML={{ __html: rendered_Equation51 }}
                />
                <div className='equation' style={{margin:'2rem 0'}}
                    dangerouslySetInnerHTML={{ __html: rendered_Equation52 }}
                />
                <h2>Multiplicamos la primera ecuación por -2 para cancelar el -4x con el 4x y de esta manera solo 
                dejar la incógnita y.</h2>
                <h2>Paso 2:</h2>
                <div className='equation' style={{margin:'2rem 0'}}
                    dangerouslySetInnerHTML={{ __html: rendered_Equation52 }}
                />
                <div className='equation' style={{margin:'2rem 0'}}
                    dangerouslySetInnerHTML={{ __html: rendered_Equation53 }}
                />
                <div className='equation' style={{margin:'2rem 0'}}
                    dangerouslySetInnerHTML={{ __html: rendered_Equation54 }}
                />
                <div className='equation' style={{margin:'2rem 0'}}
                    dangerouslySetInnerHTML={{ __html: rendered_Equation55 }}
                />
                <h2>Paso 3:</h2>
                <div className='equation' style={{margin:'2rem 0'}}
                    dangerouslySetInnerHTML={{ __html: rendered_Equation56 }}
                />
                <div className='equation' style={{margin:'2rem 0'}}
                    dangerouslySetInnerHTML={{ __html: rendered_Equation57 }}
                />
                <div className='equation' style={{margin:'2rem 0'}}
                    dangerouslySetInnerHTML={{ __html: rendered_Equation58 }}
                />
                <div className='equation' style={{margin:'2rem 0'}}
                    dangerouslySetInnerHTML={{ __html: rendered_Equation59 }}
                />
                <div className='equation' style={{margin:'2rem 0'}}
                    dangerouslySetInnerHTML={{ __html: rendered_Equation60 }}
                />

                <h1>Ejercicios:</h1>
                <div className='block-exc'>
                    <h2>1.</h2>
                    <div className='equation'
                        dangerouslySetInnerHTML={{ __html: rendered_Equation61 }}
                    />
                    <a className='button' onClick={() => solveEquation(rendered_Equation62)}>Respuesta</a>
                    {result === rendered_Equation62 ? (<div className='equation'
                        dangerouslySetInnerHTML={{ __html: result }}
                    />) : (
                        <></>
                    )             
                    };
               </div> 

                <div className='block-exc'>
                    <h2>2.</h2>
                    <div className='equation'
                        dangerouslySetInnerHTML={{ __html:rendered_Equation63 }}
                    />
                    <a className='button' onClick={() => solveEquation(rendered_Equation64)}>Respuesta</a>
                    {result === rendered_Equation64 ? (<div className='equation'
                        dangerouslySetInnerHTML={{ __html: result }}
                    />) : (
                        <></>
                    )             
                    };
                </div>
                
                <div className='block-exc'>
                    <h2>3.</h2>
                    <div className='equation'
                        dangerouslySetInnerHTML={{ __html: rendered_Equation65 }}
                    />
                    <a className='button' onClick={() => solveEquation(rendered_Equation66)}>Respuesta</a>
                    {result === rendered_Equation66 ? (<div className='equation'
                        dangerouslySetInnerHTML={{ __html: result }}
                    />) : (
                        <></>
                    )             
                    };
                </div>
                
                <div className='block-exc'>
                    <h2>4.</h2>
                    <div className='equation'
                        dangerouslySetInnerHTML={{ __html: rendered_Equation67 }}
                    />
                    <a className='button' onClick={() => solveEquation(rendered_Equation68)}>Respuesta</a>
                    {result === rendered_Equation68 ? (<div className='equation'
                        dangerouslySetInnerHTML={{ __html: result }}
                    />) : (
                        <></>
                    )             
                    };
                </div>
                
                <div className='block-exc'>
                    <h2>5.</h2>
                    <div className='equation'
                        dangerouslySetInnerHTML={{ __html: rendered_Equation69 }}
                    />
                    <a className='button' onClick={() => solveEquation(rendered_Equation70)}>Respuesta</a>
                    {result === rendered_Equation70 ? (<div className='equation'
                        dangerouslySetInnerHTML={{ __html: result }}
                    />) : (
                        <></>
                    )             
                    };
                </div>
                
                <div className='block-exc'>
                    <h2>6.</h2>
                    <div className='equation'
                        dangerouslySetInnerHTML={{ __html: rendered_Equation71 }}
                    />
                    <a className='button' onClick={() => solveEquation(rendered_Equation72)}>Respuesta</a>
                    {result === rendered_Equation72 ? (<div className='equation'
                        dangerouslySetInnerHTML={{ __html: result }}
                    />) : (
                        <></>
                    )             
                    };
                </div>
                
                <div className='block-exc'>
                    <h2>7.</h2>
                    <div className='equation'
                        dangerouslySetInnerHTML={{ __html: rendered_Equation73 }}
                    />
                    <a className='button' onClick={() => solveEquation(rendered_Equation74)}>Respuesta</a>
                    { result === rendered_Equation74 ? (<div className='equation'
                        dangerouslySetInnerHTML={{ __html: result }}
                    />):(
                        <></>
                    )}
                </div>

                <div className='block-exc'>
                    <h2>8.</h2>
                    <div className='equation'
                        dangerouslySetInnerHTML={{ __html: rendered_Equation75 }}
                    />
                    <a className='button' onClick={() => solveEquation(rendered_Equation76)}>Respuesta</a>
                    {result === rendered_Equation76 ? (<div className='equation'
                        dangerouslySetInnerHTML={{ __html: result }}
                    />) : (
                        <></>
                    )             
                    };
                </div>
            </div>

    </section>
    <Footers />
    </>
  )
}