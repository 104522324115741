import React, { useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import {faYoutube, faXTwitter, faInstagram} from '@fortawesome/free-brands-svg-icons'
import '../styles/Contacts.css'
import Navbar from '../components/navbar'
import Footers from '../components/footers'
import AOS from 'aos'
function Contacts() {
  useEffect(() => {
    AOS.init({duration:1000});
  }, []);
  useEffect(() => {
    const hoverAreas = document.querySelectorAll('.sei');

    const handleMouseOver = (e) => {
      const headingYT = e.currentTarget.querySelector('h2.yty');
      const headingX = e.currentTarget.querySelector('h2.xsx');
      const headingIg = e.currentTarget.querySelector('h2.is');
      const headingGmail = e.currentTarget.querySelector('h2.gl');
      const iconYt = e.currentTarget.querySelector('a.youtube');
      const iconIg = e.currentTarget.querySelector('a.instagram');
      const iconGmail = e.currentTarget.querySelector('a.gmail');

      if (iconYt) iconYt.style.color = '#ebe8e8'
      if (iconIg) iconIg.style.color = '#e03894';
      if (iconGmail) iconGmail.style.color='#63d496';
      if (headingYT){ headingYT.style.display = 'block'; headingYT.style.marginRight = '0rem'; headingYT.style.marginLeft='4rem'; headingYT.style.marginTop='0rem'; headingYT.style.marginBottom='0rem'; }
      if (headingX) {headingX.style.display = 'block'; headingX.style.marginRight = '0rem'; headingX.style.marginLeft='4rem'; headingX.style.marginTop='0rem'; headingX.style.marginBottom='0rem';}
      if (headingIg) {headingIg.style.display = 'block'; headingIg.style.marginRight = '0rem'; headingIg.style.marginLeft='4rem'; headingIg.style.marginTop='0rem'; headingIg.style.marginBottom='0rem';}
      if (headingGmail) {headingGmail.style.display = 'block'; headingGmail.style.marginRight = '0rem'; headingGmail.style.marginLeft='4rem'; headingGmail.style.marginTop='0rem'; headingGmail.style.marginBottom='0rem';}
    };

    const handleMouseOut = (e) => {
      const headingYT = e.currentTarget.querySelector('h2.yty');
      const headingX = e.currentTarget.querySelector('h2.xsx');
      const headingIg = e.currentTarget.querySelector('h2.is');
      const headingGmail = e.currentTarget.querySelector('h2.gl');
      const iconIg = e.currentTarget.querySelector('a.instagram');
      const iconGmail = e.currentTarget.querySelector('a.gmail');
      const iconYt = e.currentTarget.querySelector('a.youtube');

      if (iconYt) iconYt.style.color = '#e94b4b'
      if (iconIg) iconIg.style.color = '#f39c12';
      if (iconGmail) iconGmail.style.color='#f3e523';
      if (headingYT) headingYT.style.display = 'none';
      if (headingX) headingX.style.display = 'none';
      if (headingIg) headingIg.style.display = 'none';
      if (headingGmail) headingGmail.style.display = 'none';
    };

    hoverAreas.forEach(area =>{
      area.addEventListener('mouseover', handleMouseOver);
      area.addEventListener('mouseout', handleMouseOut);
    });

    return() => {
      hoverAreas.forEach(area =>{
        area.removeEventListener('mouseover', handleMouseOver);
        area.removeEventListener('mouseout', handleMouseOut);
      });
    };
    
  }, []);

  return (
    <>
    <Navbar />
    <div className='contacts'>
      <section className='contact-us'>
        <div className='img-sec' data-aos='slide-right'>
          <img style={{width:'80%'}} src={require('../assets/images/44b9fa50-fb6e-492f-bd57-d6eb73007449.png')}/>
        </div>
        <div className='contact-types' data-aos='slide-left'>
          <div className="sei yout"><a className='youtube' href=""><FontAwesomeIcon style={{zIndex:1}} className="yt" icon={faYoutube} /></a><h2 className='yty'>Próximamente</h2></div>
          <div className="sei xs"><a className='Xtwitter' href=""><FontAwesomeIcon style={{zIndex:1}} className="x" icon={faXTwitter} /></a><h2 className='xsx'>Próximamente</h2></div>
          <div className="sei igs"><a className='instagram' href="https://www.instagram.com/thebigscience/"><FontAwesomeIcon style={{zIndex:1}} className="ig" icon={faInstagram} /></a><h2 className='is'>@thebigscience</h2></div>
          <div className="sei gm"><a className='gmail' href="mailto: thebigscience0@gmail.com"><FontAwesomeIcon style={{zIndex:1}} className="gmail" icon={faEnvelope} /></a><h2 className='gl'>thebigscience0@gmail.com</h2></div>
        </div>
      </section>
    </div>
    <Footers />
    </>
  )
}

export default Contacts
