import React, { useEffect, useState } from 'react'
import Sidebar from '../../../components/Sidebar'
import Footers from '../../../components/footers';

export default function Prinhein() {
    const [isMobile, setIsMobile] = useState(window.innerWidth <=600);
    useEffect(()=> {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 600);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    });

    const styles = {
        bcgdsm:{
            padding: isMobile? '1.8rem':'2rem 25%'
        }
    }
  return (
    <>
    <Sidebar />
    <section style={styles.bcgdsm} className='bcgdsm'>

            <div className='titami'>
                <h1>Principio de Incertidumbre de Heinsenberg</h1>
                <h4>Esdras Hernández</h4>
                <h5>12 minutos de lectura</h5>
            </div>

            <div className='igsm'>
                <img src={require('../../../assets/images/3ddd7aee-b5c1-4b70-9ff7-e3b13e1df1bc.png')}/>
            </div>

            <div className='dessm'>
                <h2>Como vimos en el artículo anterior (Dualidad Onda-Partícula), lo que denominamos luz puede en realidad
                 ser un fotón (partícula) y onda a la vez. Efectos de esto es que esta misma dualidad se extiende a todas 
                 las partículas.</h2>
                 <h2>Esta dualidad nos muestra la naturaleza del mundo cuántico que es la indeterminación, es decir, 
                 no puedes saber nada con certeza absoluta. En las teorías clásicas es posible hacer experimentos y 
                 a partir de las ecuaciones podremos saber qué sucederá. En cambio, en mecánica cuántica las ecuaciones 
                 nos dan probabilidades debido a este principio fundamental de Heinsenberg que parece ser la naturaleza 
                 intrínseca de las partículas subatómicas.</h2>
            </div>
            <div className='igsm'>
                <img src={require('../../../assets/images/3e6f85c2-4c0b-4607-8ac6-5c663a65b381.png')} />
            </div>
            <div className='dessm'>
                <h2>El principio de Incertidumbre de Heisenberg nos revela que es imposible conocer la posición y momento
                 de una partícula en un instante. Se señaló anteriormente que el protón, electrón , luz, etc., todo lo 
                 que conocemos como “partículas”, son ondas y partículas al mismo tiempo. Sabemos que las ondas están 
                 en muchos lugares al mismo tiempo, en cambio, la partícula sólo puede ocupar un punto en el espacio y
                  tiempo en un instante. Es decir, si nosotros queremos medir la longitud de onda de un rayo gamma 
                  proveniente del Sol, al hacer el cálculo hacemos alusión a que el fotón es una onda y por lo tanto 
                  la posición es indeterminada.</h2>
            </div>
            <div className='igsm'>
                <img src={require('../../../assets/images/b5c3d65c-cfe1-499b-a5aa-b0e9b9e7c5e5.png')}/>
            </div>
            <div className='dessm'>
                <h2>Otra consecuencia de esto es que si se conoce el tiempo de vida de una partícula u onda, esta no 
                puede tener una energía específica, esto es debido a que una partícula con tan poco tiempo de vida 
                no puede tener una energía definida. La razón es porque la frecuencia de un estado es inversamente 
                proporcional al tiempo y que la frecuencia se conecta con la energía del estado, es decir, para poder 
                conocer la energía, el estado se debe observar por algunos ciclos.</h2>
            </div>
            <div className='dessm'>
                <h1>Referencias</h1>
                <h2>https://www.nationalgeographic.com.es/ciencia/principio-incertidumbre-y-limites-cuanticos_21758</h2>
                <h2>https://openstax.org/books/f%C3%ADsica-universitaria-volumen-3/pages/7-2-el-principio-de-incertidumbre-de-heisenberg</h2>

            </div>

    </section>
    <Footers />
    </>
  )
}