import React, { useEffect, useState } from 'react'
import Sidebar from '../../components/Sidebar'
import '../../styles/Algebra.css'
import Footers from '../../components/footers';

export default function Algebra() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <=600);
    useEffect(()=> {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 600);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    });

    const styles = {
        tpn:{
          padding:isMobile? '1rem': '1rem 10rem'
        },
        text:{
          fontSize: isMobile? '1.2rem': 'auto'
        },
        seetop:{
          fontSize: isMobile? '0.9rem':'auto'
        },
        seetop1:{
          margin: isMobile? '1rem':'2rem 15%'
        }
    }
  return (
    <>
        <Sidebar />
        <section className='tpcs'>
          <div className='mtpc'>
            <h1>Álgebra</h1>
            <hr />
          </div>
          <div style={styles.tpn} className='tpn'>

            <div className='tpo'>
              <a className='htp amo' href='/mate/algebra/ecuaciones_lineales'>
                <h2 style={styles.text} className='h2tt'>Ecuaciones lineales</h2>
                <div style={styles.seetop1} className='seetop'>
                    <h1 style={styles.seetop}>Las ecuaciones lineales son expresiones matemáticas de la forma ax+b=0 que representan 
                    líneas rectas en un plano. Son fundamentales para resolver problemas en diversas áreas al describir 
                    relaciones proporcionales entre variables.</h1>
                </div>
              </a>
            </div>

            <div className='tpt'>
              <a className='htp ams' href='/mate/algebra/sistemas_ecuaciones'>
                <h2 style={styles.text} className='h2tt'>Sistemas de ecuaciones</h2>
                <div style={styles.seetop1} className='seetop'>
                  <h1 style={styles.seetop}>Un sistema de ecuaciones lineales es un conjunto de dos o más ecuaciones lineales con las mismas 
                  variables. La solución del sistema es el valor de las variables que satisface todas las ecuaciones 
                  simultáneamente. Estos sistemas se utilizan para resolver problemas donde se deben encontrar valores 
                  comunes a varias condiciones lineales.</h1>
                </div>
              </a>
            </div>

          </div>
        </section>
        <Footers />
    </>
  )
}