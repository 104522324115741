import React from 'react'
import {Link} from 'react-router-dom'
import '../styles/Navbar.css'
function navbar() {
  return (
    <div className='nav-bar'>
    <div className='navbar-1'> {/*  */}
      <div className='navbar'>
      <div className='leftSide'>
        <label>The Big Science</label>
      </div>
      <div className='rightSide'>
        <Link className='linkhac' to="/"> Home </Link>
        {/* <Link to='/news'> News </Link> */}
        <Link className='linkhac' to='/about'> Acerca </Link>
        <Link className='linkhac' to='/contact'> Contacto </Link>
      </div>
    </div>
    <div className='navbar-2'>
      <div className='nav-center'>
        <Link to='/fisica'>Física</Link>
        <Link to='/programa'>Programación</Link>
        <Link to='/mate'>Matemáticas</Link>
      </div>
    </div>
    </div>
    </div>
  )
}

export default navbar
