import React, {useEffect, useState} from 'react'
import Sidebar from '../../../components/Sidebar'
import equationsKatex from '../../../components/equations';
import '../../../styles/Eculins.css'
import Footers from '../../../components/footers';

export default function Eculin() {
    const [result, setResult] =useState(null);

    const solveEquation = (result) => {
        setResult(result);
    };

    const equation = '\{x+4=5}';
    const equation_1 = '\{x=5-4}';
    const equation_2 = '\{x=1}';
    const equation_3 = '\{x-9=12}';
    const equation_4 = '\{x=12+9}';
    const equation_5 = '\{x=21}';
    const equation_6 = '\{3x=6}';
    const equation_7 = '\{x= \\frac{6}{3}}';
    const equation_8 = '\{x=2}';
    const equation_9 = '\{-4x=8}';
    const equation_10 = '\{x= \\frac{8}{-4}}';
    const equation_11 = '\{x=-2}';
    const equation_12 = '\{\\frac{x}{3} = 6}';
    const equation_13 = '\{x= (6)(3)}';
    const equation_14 = '\{x=18}';
    const equation_15 = '\{\\frac{x}{-6} = 4}';
    const equation_16 = '\{x=(4)(-6)}';
    const equation_17 = '\{x=-24}';
    const equation_18 = '\{4x-3=9}';
    const equation_19 = '\{4x=9+3}';
    const equation_20 = '\{4x=12}';
    const equation_21 = '\{x= \\frac{12}{4}}';
    const equation_22 = '\{x=3}';
    const equation_23 = '\{\\frac{3x}{7} +5=8}';
    const equation_24 = '\{\\frac{3x}{7}=8-5}';
    const equation_25 = '\{\\frac{3x}{7}=3}';
    const equation_26 = '\{x = \\frac{(7)(3)}{3}}';
    const equation_27 = '\{x = 7}';
    const equation_28 = '\{-4x+8 =0}';
    const equation_29 = '\{-4x=-8}';
    const equation_30 = '\{x= \\frac{-8}{-4}}';
    const equation_31 = '\{x = 2}';
    const equation_32 = '\{x+1=2}';
    const equation_33 = '\{x+1-1 = 2-1}';
    const equation_34 = '\{x=1}';
    const equation_35 = '\{\\frac{3x}{7}=21}';
    const equation_36 = '\{\\frac{3x}{7} \\times \\frac{7}{3} = 21 \\times \\frac{7}{3}}';

    const equation_37 = '\{x = 49}';
    const equation_38 = '\{x+4=34}';
    const equation_39 = '\{x-3=12}';
    const equation_40 = '\{5-x=23}';
    const equation_41 = '\{3x=9}';
    const equation_42 = '\{\\frac{8x}{9}=10}';
    const equation_43 = '\{\\frac{x}{9}=9}';
    const equation_44 = '\{\\frac{-3x}{5} + 3=10}';
    const equation_45 = '\{\\frac{x}{5}-2=2}';

    const result_1 = '\{x=30}';
    const result_2 = '\{x=15}';
    const result_3 = '\{x=-18}';
    const result_4 = '\{x=3}';
    const result_5 = '\{x= \\frac{45}{4}}';
    const result_6 = '\{x=81}';
    const result_7 = '\{x= \\frac{35}{-3}}';
    const result_8 = '\{x = 20}';

    const renderedEquation = equationsKatex(equation);
    const renderedEquation_1 = equationsKatex(equation_1)
    const renderedEquation_2 = equationsKatex(equation_2);
    const renderedEquation_3 = equationsKatex(equation_3);
    const renderedEquation_4 = equationsKatex(equation_4);
    const renderedEquation_5 = equationsKatex(equation_5);
    const renderedEquation_6 = equationsKatex(equation_6);
    const renderedEquation_7 = equationsKatex(equation_7);
    const renderedEquation_8 = equationsKatex(equation_8);
    const renderedEquation_9 = equationsKatex(equation_9);
    const renderedEquation_10 = equationsKatex(equation_10);
    const renderedEquation_11 = equationsKatex(equation_11);
    const renderedEquation_12 = equationsKatex(equation_12);
    const renderedEquation_13 = equationsKatex(equation_13);
    const renderedEquation_14 = equationsKatex(equation_14);
    const renderedEquation_15 = equationsKatex(equation_15);
    const renderedEquation_16 = equationsKatex(equation_16);
    const renderedEquation_17 = equationsKatex(equation_17);
    const renderedEquation_18 = equationsKatex(equation_18);
    const renderedEquation_19 = equationsKatex(equation_19);
    const renderedEquation_20 = equationsKatex(equation_20);
    const renderedEquation_21 = equationsKatex(equation_21);
    const renderedEquation_22 = equationsKatex(equation_22);
    const renderedEquation_23 = equationsKatex(equation_23);
    const renderedEquation_24 = equationsKatex(equation_24);
    const renderedEquation_25 = equationsKatex(equation_25);
    const renderedEquation_26 = equationsKatex(equation_26);
    const renderedEquation_27 = equationsKatex(equation_27);
    const renderedEquation_28 = equationsKatex(equation_28);
    const renderedEquation_29 = equationsKatex(equation_29);
    const renderedEquation_30 = equationsKatex(equation_30);
    const renderedEquation_31 = equationsKatex(equation_31);
    const renderedEquation_32 = equationsKatex(equation_32);
    const renderedEquation_33 = equationsKatex(equation_33);
    const renderedEquation_34 = equationsKatex(equation_34);
    const renderedEquation_35 = equationsKatex(equation_35);
    const renderedEquation_36 = equationsKatex(equation_36);
    const renderedEquation_37 = equationsKatex(equation_37);
    const renderedEquation_38 = equationsKatex(equation_38);
    const renderedEquation_39 = equationsKatex(equation_39);
    const renderedEquation_40 = equationsKatex(equation_40);
    const renderedEquation_41 = equationsKatex(equation_41);
    const renderedEquation_42 = equationsKatex(equation_42);
    const renderedEquation_43 = equationsKatex(equation_43);
    const renderedEquation_44 = equationsKatex(equation_44);
    const renderedEquation_45 = equationsKatex(equation_45);

    const renderResult_1 = equationsKatex(result_1);
    const renderResult_2 = equationsKatex(result_2);
    const renderResult_3 = equationsKatex(result_3);
    const renderResult_4 = equationsKatex(result_4);
    const renderResult_5 = equationsKatex(result_5);
    const renderResult_6 = equationsKatex(result_6);
    const renderResult_7 = equationsKatex(result_7);
    const renderResult_8 = equationsKatex(result_8);

    const [isMobile, setIsMobile] = useState(window.innerWidth <=600);
    useEffect(()=> {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 600);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    });

    const styles = {
        bcgdsm:{
            padding: isMobile? '1.8rem':'2rem 25%'
        }
    }

  return (
    <>
    <Sidebar />
    <section style={styles.bcgdsm} className='bcgdsm'>

            <div className='titami'>
                <h1>Ecuaciones Lineales</h1>
                <h4>Esdras Hernández</h4>
                <h5>12 minutos de lectura</h5>
            </div>

            <div className='igsm'>
                <img src={require('../../../assets/images/openart-33a7d22c95884b7083931ba17af0cef3_raw.jpg')}/>
            </div>

            <div className='dessm'>
                <h2>El Álgebra es una de las áreas de las matemáticas más usadas en el día a día, sus aplicaciones son 
                desde el total de venta de los productos de un supermercado hasta ecuaciones que nos ayudan a descubrir
                 nuevas partículas de la naturaleza.</h2>
                 <h2>En este caso, nos centraremos en el álgebra lineal que es el más sencillo de todos, sin embargo, 
                 es de suma importancia que aprendas algunas leyes del álgebra porque son utilizadas en cualquier ecuación algebraica.</h2>
            </div>
            <div className='igsm'>
                <img src={require('../../../assets/images/936cad11-440c-4dd8-8acc-f66923e88719.png')} />
            </div>
            <div className='dessm'>
                <h2>Pero, para comenzar: ¿qué es una ecuación? Una ecuación es una operación con números e incógnitas 
                que es igualado a algún número o incógnita. Entonces, x + 4=2, esto es una ecuación. </h2>
                <h2>¿Qué es una representación algebraica? Una representación algebraica es una operación con
                 números y letras sin estar igualado a algún valor, entonces, x+4 es una representación algebraica.</h2>
                <h2>La diferencia entre una ecuación y una representación radica en el signo de igual, mientras que una ecuación está igualada a algún valor, 
                la representación algebraica no lo está.</h2>
            </div>
            <div className='dessm'>
                <h2>¿Qué es una incógnita? Una incógnita es un valor a encontrar (no sabemos cuál es), es 
                por esto que se representa con una letra.</h2>
                <h2>¿Qué se hace en una ecuación? El objetivo de una ecuación, es encontrar la incógnita, es decir, encontrar el valor perdido.</h2>
                <h2>Primero resolveremos esta ecuación:</h2>
                <div className='equation'
                    dangerouslySetInnerHTML={{ __html: renderedEquation }}
                />    
                <h2>¿Cómo encontramos la x? A simple vista, diremos que x es igual a 1, porque 4+1 es igual a 5, 
                esto es correcto. Sin embargo, tenemos que hacerlo de un modo algebraico.</h2>
                <h2>Para hacerlo de esta manera, tendremos que hacer un proceso llamado: “despejar x”, es decir, que x se quede sola de un lado de la ecuación (puede ser en el izquierdo o derecho).</h2>
                <h2>Para lograr esto, debemos de seguir unas reglas:</h2>
                <h2>Cuando un número se está sumando con otro número o incógnita, el número se pasa al otro lado de la 
                ecuación (más tarde veremos cómo y por qué) restando, es decir, con su signo contrario. Si un número está
                 restando de un lado de la ecuación, se pasa sumando al otro lado de la ecuación sumando. Si un número se
                  está multiplicando, pasa al otro lado de la ecuación dividiendo. Si un número se está dividiendo, pasa 
                  al otro lado de la ecuación multiplicando. Es decir, signos contrarios. El signo contrario de la suma 
                  es el menos, el signo contrario de la multiplicación es la división y viceversa.</h2>
                
                <h2>Entonces, ya sabemos lo que tenemos que hacer, despejar x.</h2>
                <h2>En este caso, el 4 positivo del lado izquierdo deberá pasar al lado derecho como negativo.</h2>
                <h2>Entonces:</h2>
                <div className='equation'
                    dangerouslySetInnerHTML={{ __html: renderedEquation }}
                />  
                <div className='equation'
                    dangerouslySetInnerHTML={{ __html: renderedEquation_1 }}
                />
                <div className='equation'
                    dangerouslySetInnerHTML={{ __html: renderedEquation_2 }}
                />
                <h2>De esta manera es como encontramos el valor de x.</h2>
                <h2>Hagamos otro ejercicio:</h2>
                <div className='equation'
                    dangerouslySetInnerHTML={{ __html: renderedEquation_3 }}
                />
                <h2>Despejamos x. El 9 negativo del lado izquierdo lo pasaremos al lado derecho como número positivo.</h2>
                <h2>Entonces:</h2>
                <div className='equation'
                    dangerouslySetInnerHTML={{ __html: renderedEquation_3 }}
                />
                <div className='equation'
                    dangerouslySetInnerHTML={{ __html: renderedEquation_4 }}
                />
                <div className='equation'
                    dangerouslySetInnerHTML={{ __html: renderedEquation_5 }}
                />
                <h2>Y así es como encontramos el valor de x.</h2>
                <h2>Veamos un caso diferente.</h2>
                <div className='equation'
                    dangerouslySetInnerHTML={{ __html: renderedEquation_6 }}
                />
                <h2>Esto es una multiplicación ya que el 3x representa que 3 se multiplica con x (cuando dos números o incógnitas estén juntos de esta manera, es una multiplicación).</h2>
                <h2>En este caso, el despeje será diferente, debemos de dejar la x sola, eso incluye hacer que el 3 pase al otro lado de la ecuación, ¿cómo lo hacemos? Con la operación contraria, es decir, si está multiplicando de un lado, pasará al otro lado dividiendo.</h2>
                <h2>Entonces:</h2>
                <div className='equation'
                    dangerouslySetInnerHTML={{ __html: renderedEquation_6 }}
                />
                <div className='equation'
                    dangerouslySetInnerHTML={{ __html: renderedEquation_7 }}
                />
                <div className='equation'
                    dangerouslySetInnerHTML={{ __html: renderedEquation_8 }}
                />
                <h2>Resolvamos otra ecuación como esta.</h2>
                <div className='equation'
                    dangerouslySetInnerHTML={{ __html: renderedEquation_9 }}
                />
                <h2>En este caso, tenemos una multiplicación de -4 por x, al ser una multiplicación, el -4 pasará al otro lado como divisor sin cambiar su signo (esto debido a que en multiplicaciones y divisiones se conserva el signo).</h2>
                <h2>Entonces:</h2>
                <div className='equation'
                    dangerouslySetInnerHTML={{ __html: renderedEquation_9 }}
                />
                <div className='equation'
                    dangerouslySetInnerHTML={{ __html: renderedEquation_10 }}
                />
                <div className='equation'
                    dangerouslySetInnerHTML={{ __html: renderedEquation_11 }}
                />
                <h2>Ahora hagamos una ecucación con división.</h2>
                <div className='equation'
                    dangerouslySetInnerHTML={{ __html: renderedEquation_12 }}
                />
                <h2>Aquí despejamos x nuevamente. En este caso, tenemos una división, x se divide sobre 3, por lo que el 3 que está dividiendo pasará al otro lado como multiplicación (conservando su signo).</h2>
                <h2>Entonces:</h2>
                <div className='equation'
                    dangerouslySetInnerHTML={{ __html: renderedEquation_12 }}
                />
                <div className='equation'
                    dangerouslySetInnerHTML={{ __html: renderedEquation_13 }}
                />
                <div className='equation'
                    dangerouslySetInnerHTML={{ __html: renderedEquation_14 }}
                />
                <h2>Ahora, hagamos esto mismo pero con signo negativo.</h2>
                <div className='equation'
                    dangerouslySetInnerHTML={{ __html: renderedEquation_15 }}
                />
                <h2>Despejamos x. El -6 pasará al otro lado multiplicando con el 4, conservando su signo.</h2>
                <h2>Entonces:</h2>
                <div className='equation'
                    dangerouslySetInnerHTML={{ __html: renderedEquation_15 }}
                />
                <div className='equation'
                    dangerouslySetInnerHTML={{ __html: renderedEquation_16 }}
                />
                <div className='equation'
                    dangerouslySetInnerHTML={{ __html: renderedEquation_17 }}
                />
                <h2>Todo esto lo realizamos siguiendo las leyes del álgebra:</h2>
                <ul>
                    <li>Si un número o incógnita está sumando de un lado, pasa al otro lado como resta.</li>
                    <li>Si un número o incógnita está restando de un lado, pasa al otro lado como suma.</li>
                    <li>Si un número o incógnita está multiplicando de un lado, pasa al otro lado como división (conservando su signo).</li>
                    <li>Si un número o incógnita está dividido de un lado, pasa al otro lado como multiplicación (conservando su signo).</li>
                </ul> 
                <h2>Avancemos de nivel:</h2>
                <div className='equation'
                    dangerouslySetInnerHTML={{ __html: renderedEquation_18 }}
                />
                <h2>Tenemos esta ecuación, la pregunta es: ¿cómo la resolvemos? Lo que primeramente haremos es pasar el 
                -3 al lado derecho de la ecuación como un número positivo y se sumará con él 9.</h2>
                <h2>Entonces:</h2>
                <div className='equation'
                    dangerouslySetInnerHTML={{ __html: renderedEquation_18 }}
                />
                <div className='equation'
                    dangerouslySetInnerHTML={{ __html: renderedEquation_19 }}
                />
                <h2>Por último, el 4 que se está multiplicando, entonces pasará al otro lado como divisor.</h2>
                <div className='equation'
                    dangerouslySetInnerHTML={{ __html: renderedEquation_20 }}
                />
                <div className='equation'
                    dangerouslySetInnerHTML={{ __html: renderedEquation_21 }}
                />
                <div className='equation'
                    dangerouslySetInnerHTML={{ __html: renderedEquation_22 }}
                />
                <h2>Resolvamos otro problema:</h2>
                <div className='equation'
                    dangerouslySetInnerHTML={{ __html: renderedEquation_23 }}
                />
                <div className='equation'
                    dangerouslySetInnerHTML={{ __html: renderedEquation_24 }}
                />
                <div className='equation'
                    dangerouslySetInnerHTML={{ __html: renderedEquation_25 }}
                />
                <div className='equation'
                    dangerouslySetInnerHTML={{ __html: renderedEquation_26 }}
                />
                <div className='equation'
                    dangerouslySetInnerHTML={{ __html: renderedEquation_27 }}
                />
                <h2>¿Cómo resolvimos esto? Primero, se pasa el 5 positivo al otro lado como negativo. Posteriormente,
                 el 7 se pasa al otro lado multiplicando y el 3 dividiendo (conservando sus signos en el caso de la 
                 multiplicación y división).</h2>
                <h2>Resolvamos un último caso:</h2>
                <div className='equation'
                    dangerouslySetInnerHTML={{ __html: renderedEquation_28 }}
                />
                <div className='equation'
                    dangerouslySetInnerHTML={{ __html: renderedEquation_29 }}
                />
                <div className='equation'
                    dangerouslySetInnerHTML={{ __html: renderedEquation_30 }}
                />
                <div className='equation'
                    dangerouslySetInnerHTML={{ __html: renderedEquation_31 }}
                />
                <h2>Primero pasamos el 8 positivo al otro lado de la ecuación como negativo y posteriormente lo dividimos 
                sobre -4.</h2>
                <h2>¿Qué significa “pasar al otro lado de la ecuación”? Decir un número se pasa al lado derecho/izquierdo 
                de la ecuación, matemáticamente no está bien dicho, sin embargo, así es como muchos estudiantes aprenden. 
                Pongamos un ejemplo:</h2>
                <div className='equation'
                    dangerouslySetInnerHTML={{ __html: renderedEquation_32 }}
                />
                <h2>Sabemos que x será 1, lo podremos resolver algebraicamente, sin embargo, ¿cuál el la razón matemática de que el 1 se pase al otro lado como -1? </h2>
                <h2>Una ecuación es como una balanza que debe estar siempre equilibrada, si agregas un número de un lado, del otro lado también deberás agregarlo. Entonces, lo que haremos es lo siguiente:</h2>
                <div className='equation'
                    dangerouslySetInnerHTML={{ __html: renderedEquation_33 }}
                />
                <div className='equation'
                    dangerouslySetInnerHTML={{ __html: renderedEquation_34 }}
                />
                <h2>Este es el procedimiento matemático correcto, escribo -1 en ambos lados de la ecuación y de un lado se convierte en 0 (izquierdo) y del otro lado queda como -1 (derecho).</h2>
                <h2>Para multiplicaciones y divisiones:</h2>
                <div className='equation'
                    dangerouslySetInnerHTML={{ __html: renderedEquation_35 }}
                />
                <div className='equation'
                    dangerouslySetInnerHTML={{ __html: renderedEquation_36 }}
                />
                <div className='equation'
                    dangerouslySetInnerHTML={{ __html: renderedEquation_37 }}
                />
                <h2>Lo que hacemos es multiplicar todo por 7/3 para eliminar el 3/7 que está multiplicando a x.</h2>
                <h1>Ejercicios:</h1>

                <div className='block-exc'>
                    <h2>1.</h2>
                    <div className='equation'
                        dangerouslySetInnerHTML={{ __html: renderedEquation_38 }}
                    />
                    <a className='button' onClick={() => solveEquation(renderResult_1)}>Respuesta</a>
                    {result === renderResult_1 ? (<div className='equation'
                        dangerouslySetInnerHTML={{ __html: result }}
                    />) : (
                        <></>
                    )             
                    };
               </div> 

                <div className='block-exc'>
                    <h2>2.</h2>
                    <div className='equation'
                        dangerouslySetInnerHTML={{ __html: renderedEquation_39 }}
                    />
                    <a className='button' onClick={() => solveEquation(renderResult_2)}>Respuesta</a>
                    {result === renderResult_2 ? (<div className='equation'
                        dangerouslySetInnerHTML={{ __html: result }}
                    />) : (
                        <></>
                    )             
                    };
                </div>
                
                <div className='block-exc'>
                    <h2>3.</h2>
                    <div className='equation'
                        dangerouslySetInnerHTML={{ __html: renderedEquation_40 }}
                    />
                    <a className='button' onClick={() => solveEquation(renderResult_3)}>Respuesta</a>
                    {result === renderResult_3 ? (<div className='equation'
                        dangerouslySetInnerHTML={{ __html: result }}
                    />) : (
                        <></>
                    )             
                    };
                </div>
                
                <div className='block-exc'>
                    <h2>4.</h2>
                    <div className='equation'
                        dangerouslySetInnerHTML={{ __html: renderedEquation_41 }}
                    />
                    <a className='button' onClick={() => solveEquation(renderResult_4)}>Respuesta</a>
                    {result === renderResult_4 ? (<div className='equation'
                        dangerouslySetInnerHTML={{ __html: result }}
                    />) : (
                        <></>
                    )             
                    };
                </div>
                
                <div className='block-exc'>
                    <h2>5.</h2>
                    <div className='equation'
                        dangerouslySetInnerHTML={{ __html: renderedEquation_42 }}
                    />
                    <a className='button' onClick={() => solveEquation(renderResult_5)}>Respuesta</a>
                    {result === renderResult_5 ? (<div className='equation'
                        dangerouslySetInnerHTML={{ __html: result }}
                    />) : (
                        <></>
                    )             
                    };
                </div>
                
                <div className='block-exc'>
                    <h2>6.</h2>
                    <div className='equation'
                        dangerouslySetInnerHTML={{ __html: renderedEquation_43 }}
                    />
                    <a className='button' onClick={() => solveEquation(renderResult_6)}>Respuesta</a>
                    {result === renderResult_6 ? (<div className='equation'
                        dangerouslySetInnerHTML={{ __html: result }}
                    />) : (
                        <></>
                    )             
                    };
                </div>
                
                <div className='block-exc'>
                    <h2>7.</h2>
                    <div className='equation'
                        dangerouslySetInnerHTML={{ __html: renderedEquation_44 }}
                    />
                    <a className='button' onClick={() => solveEquation(renderResult_7)}>Respuesta</a>
                    { result === renderResult_7 ? (<div className='equation'
                        dangerouslySetInnerHTML={{ __html: result }}
                    />):(
                        <></>
                    )}
                </div>

                <div className='block-exc'>
                    <h2>8.</h2>
                    <div className='equation'
                        dangerouslySetInnerHTML={{ __html: renderedEquation_45 }}
                    />
                    <a className='button' onClick={() => solveEquation(renderResult_8)}>Respuesta</a>
                    {result === renderResult_8 ? (<div className='equation'
                        dangerouslySetInnerHTML={{ __html: result }}
                    />) : (
                        <></>
                    )             
                    };
                </div>
            </div>
    </section>
    <Footers />
    </>
  )
}