import React, { useEffect, useState } from 'react'
import '../styles/Sidebar.css'
import Sidebar from '../components/Sidebar'
import '../styles/Topics_Programacion.css'
import VideoProgramacion from '../assets/PythonVideo.mp4'
import Footers from '../components/footers';

export default function Programación() {
  // useEffect(() =>{
  //   const screenWidth = window.innerWidth;
  //   if (screenWidth > 600) {
  //     AOS.init({ duration: 1000 });
  //   } else {
  //     AOS.init({ disable: true });
  //   }
  // }, [])

  const [isMobile, setIsMobile] = useState(window.innerWidth <=1014);
    useEffect(()=> {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 1014);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    });

    const styles = {
        pse:{
            margin: isMobile? '2rem':'5rem 12rem'
        },
        prse:{
          display:'grid',
          gridTemplateColumns: isMobile? 'repeat(auto-fit, minmax(1, 1fr))': 'repeat(auto-fit, minmax(2, 1fr))',
          gridTemplateRows: isMobile? 'repeat(auto-fit, minmax(2, 1fr))':'minmax(100px, auto)'
        },
        blh:{
          gridRow: isMobile? '2':'1',
          gridColumn: isMobile? '1': '1',
          margin: isMobile? '2rem': '4rem'
        },
        imgprse:{
          gridColumn: isMobile? '1':'2',
          gridRow: isMobile? '1':'1',
          width: isMobile? '100%': 'auto',
          display: isMobile? 'flex':'auto', // Use flexbox in the parent container
          justifyContent: isMobile? 'center':'auto', // Center the image in the container
          alignItems: isMobile? 'center':'auto', // Center it vertically if needed
          padding: isMobile ? '2rem' : '0rem'
        },
        img:{
          width: isMobile? '80%': 'auto',
          // width: window.innerWidth < 600 ? '80%' : (isMobile ? '60%' : 'auto'),
          margin: isMobile? '0 auto': 'auto', // Center the image horizontally
          display: isMobile? 'block':'auto' 
        },
        gridboxtops:{
          display:'grid',
          gridTemplateColumns: isMobile? 'repeat(auto-fit, minmax(1, 1fr))': 'repeat(auto-fit, minmax(2, 1fr))',
          gridTemplateRows: isMobile? 'repeat(auto-fit, minmax(4, 1fr))':'minmax(100px, auto)'
        },
        meca:{
          gridColumn: isMobile? '1':'1',
          gridRow: isMobile? '1':'1',
        },
        relat:{
          gridColumn: isMobile? '1':'2',
          gridRow: isMobile? '2':'1',
        },
        descriptions:{
          // margin: isMobile? '-33rem 0': '-32rem 0',
          // margin: window.innerWidth < 600 ? '-35rem 0': '-32rem 0',
          margin: window.innerWidth < 600 ? '-33rem 0' : (isMobile ? '-33rem 0' : '-32rem 0')
        },
    }
  return (
    <>
      <Sidebar />
      <section className='grid'>
        <div className='bg'>
          <div className="bg-f">
            <video src={VideoProgramacion} autoPlay loop muted />

            <div className="title-f">
              <h1 style={{color:'#b0aeae'}}>Programación</h1>
            </div>
          </div>
        </div>

        <div style={styles.pse} className='pse'>
          <div style={styles.prse} className='prse-j'>
            <div style={styles.blh} className='blh-j'>
              <blockquote>La Innovación es lo que distingue a un líder de un seguidor.</blockquote>
              <h3>Steve Jobs</h3>
            </div>
            <div style={styles.imgprse} className='img-prse'>
              <img style={styles.img} src={require('../assets/images/SteveJobs.png')} />
            </div>
          </div>
        </div>
        
        <div style={styles.gridboxtops} className='grid-box-tops'>
          {/* <div className='top'>
            <div className='top-box'> */}
              <a style={styles.meca} href='/programa/python' className='top py'>
                <div className='names'><h2>Python</h2></div>
                <div style={styles.descriptions} className='descriptions'><h2>Python es un lenguaje de programación de alto nivel utilizado para la creación de sitios web, programación de robots, Machine Learning, ciencias de datos, etc.</h2></div>
              </a>
            {/* </div>
          </div> */}

          {/* <div className='top'>
            <div className='top-box'> */}
              <a style={styles.relat} href='#' className='top web-d'>
                <div className='names'><h2>Desarollo Web</h2></div>
                <div style={styles.descriptions} className='descriptions'><h2>Próximamente.</h2></div>
              </a>
            {/* </div>
          </div> */}

          {/* <div className='top'>
            <div className='top-box'> */}
              {/* <a href='#' className='top mecac'>
                <div className='names'><h2>Mecánica cuántica</h2></div>
                <div className='descriptions'><h2>La Mecánica Cuántica nace con la Radiación del Cuerpo Negro por
                Max Planck en 1905, descubrió que los fotones pequeños paquetes de energía llamados "quanta".
                La Mecánica cuántica estudia la naturaleza de los sistemas subatómicos.</h2></div>
              </a> */}
            {/* </div>
          </div> */}

          {/* <div className='top'>
            <div className='top-box'> */}
              {/* <a href='#' className='top nuuni'>
                <div className='names'><h2>Nuestro Universo</h2></div>
                <div className='descriptions'><h2>La Teoría de la Relatividad fue publicada en los años 1905 
                (relatividad especial) y 1915 (relatividad general) por Albert Einstein. Esta teoría nos habla 
                acerca de las leyes de los cuerpos en marcos de referencia distinta y la naturaleza de la gravedad.</h2></div>
              </a> */}
            {/* </div>
          </div> */}
        </div>

      </section> 
      <Footers />
    </>
  )
}