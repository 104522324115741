import React, { useEffect, useState } from 'react'
import Sidebar from '../../../components/Sidebar'
import Footers from '../../../components/footers';

export default function Duaparon() {
    const [isMobile, setIsMobile] = useState(window.innerWidth <=600);
    useEffect(()=> {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 600);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    });

    const styles = {
        bcgdsm:{
            padding: isMobile? '1.8rem':'2rem 25%'
        }
    }
  return (
    <>
    <Sidebar />
    <section style={styles.bcgdsm} className='bcgdsm'>

            <div className='titami'>
                <h1>Dualidad Onda-Partícula</h1>
                <h4>Esdras Hernández</h4>
                <h5>12 minutos de lectura</h5>
            </div>

            <div className='igsm'>
                <img src={require('../../../assets/images/d3330669-4199-447d-9062-f34124d53e60.png')}/>
            </div>

            <div className='dessm'>
                <h2>En 1670, dos de los grandes físicos de esa época, Isaac Newton y Christiaan Huygens entraron en un 
                debate acerca de la naturaleza de la luz. Newton creía que la luz estaba compuesta de partículas 
                (corpúsculos) mientras que Huygens pensaba que la luz estaba formada por ondas. Sin embargo, en 1803, 
                Thomas Young realizó un experimento llamado interferencia de la doble rendija. Años más tarde, James 
                Clerk Maxwell publicó su teoría en la que describe a la luz como una partícula. Analizemos bien todo 
                esto.</h2>
            </div>
            <div className='igsm'>
                <img src={require('../../../assets/images/f178073a-0ef3-409c-9997-5a73a30d38e9.png')} />
            </div>
            <div className='dessm'>
                <h2>¿Qué es la doble rendija? ¿Qué dice la teoría de Maxwell? Como se mencionó, entre Newton y Huygens 
                existía un pequeña rivalidad sobre quién tenía la razón acerca de la naturaleza de la luz. Thomas Young 
                en su experimento determinó que la luz se comportaba como una onda, pero, ¿cómo puede ser esto? El 
                experimento se puede reinterpretar en lo siguiente: colocas dos paredes de cualquier material cerca 
                una de la otra en un cuarto oscuro. En la primera pared harás dos pequeñas aberturas parecidas a dos
                 puertas pequeñas. A continuación encendemos una linterna en medio de las dos paredes. </h2>
                <h2>Si la teoría de Newton es correcta entonces las partículas de la luz deberán chocar con la pared que
                 está entre las dos aperturas y por lo tanto no deberá alumbrar más allá de las dos pequeñas aperturas, 
                 esto debido a que la luz se comporta como partícula, es decir, que solo pueden estar en un lugar a la 
                 vez y dirigirse únicamente a una sola posición, en este caso, en medio de las dos aperturas. </h2>
            </div>
            <div className='igsm'>
                <img src={require('../../../assets/images/91668dc6-5299-4e4f-9341-f5796b8b2626.png')}/>
            </div>
            <div className='dessm'>
                <h2>Si la teoría de Huygens es correcta entonces la luz aún al chocar con la pared, deberá de alumbrar 
                más allá de las dos aperturas, ¿por qué? Esto es debido a que la luz (en la teoría de Huygens) se 
                comporta como una onda, una onda puede tener más de una posición a la vez. Si tú arrojas una piedra 
                a un lago, verás cómo se formará un círculo pequeño y posteriormente se hará más grande, esto es 
                precisamente una onda y, como te puedes dar cuenta, ocupa más de un punto a la vez. </h2>
                <h2>Sin embargo, que la luz sea una onda tiene un efecto importante y es que se pensará: “si la luz 
                es una onda y hay dos aperturas, entonces, la luz hace interferencia, una onda con la otra”, y esto 
                es correcto, pero lo asombroso aquí es que las ondas al chocar no pierden su esencia, simplemente 
                continúan su mismo camino sin dificultad, es decir, no se colisionará con la otra onda de luz y 
                modificar su esencia y dirección, lo único es que crearán una tercera onda con dirección hacia adelante. 
                Imagínate que lanzas dos piedras muy cerca una de la otra a un lago en reposo, verás que formarán dos 
                círculos pequeños al momento del impacto (ondas) y posteriormente se harán grandes y llegará el punto 
                en que choquen o se toquen y las ondas al seguir creciendo no cambiarán su dirección o esencia simplemente
                 crearán una tercera onda (interferencia) y su dirección será hacia adelante (las otras dos ondas 
                 continúan su camino normal).</h2>
            </div>
            <div className='igsm'>
                <img src={require('../../../assets/images/f6783aea-fac2-42ea-8c76-f38d3eaa87f7.png')} />
            </div>
            <div className='dessm'>
                <h2>En el experimento se encontró que la luz iluminaba más allá de las dos aperturas y parecía haber 
                interferencia justamente en medio de las dos paredes, es decir, una tercera onda creada a partir de la 
                interferencia y por lo tanto se concluyó que la luz es una onda. Posteriormente, Maxwell formuló su 
                teoría del electromagnetismo en que explica más a fondo la naturaleza ondulatoria de la luz siendo esta 
                la combinación del campo eléctrico y magnético.</h2>
                <h2>Hasta este punto se creía que el problema estaba resuelto y no había más que modificar. Pero años más
                 tarde, surgió un problema: la radiación del cuerpo negro. El cuerpo negro como tal no existe es un tipo de 
                 idealización de un cuerpo capaz de absorber toda la radiación recibida y emitirla. Se supone que el cuerpo negro 
                 está formado por átomos que se encuentran vibrando alrededor de un punto de equilibrio.</h2>
            </div>

            <div className='igsm'>
                <img src={require('../../../assets/images/49bee922-59d9-4d03-84ca-6023c3f8c5ab.jpg')} />
            </div>
            <div className='dessm'>
                <h2>Cuando se le aplicó la teoría clásica de Maxwell se dieron cuenta que la curva teórica emitida no 
                coincidía con la curva real, predecía que un cuerpo como estos debía de emitir energía infinita. Esto 
                es más conocido como catástrofe ultravioleta.</h2>
                <h2>En 1900, Max Planck propuso una solución a este problema, tratar a la energía como cuantos de luz 
                (llamados quantums), es decir, ya no tratar a la energía como una emisora y absorbente continua, sino 
                a través de cuantos, es decir, de manera discontinua.</h2>
            </div>

            <div className='igsm'>
                <img src={require('../../../assets/images/bf4deab4-9e24-4044-9e8f-cd5b9c91d275.jpg')} />
            </div>
            <div className='dessm'>
                <h2>Ahora, la pregunta es: ¿cómo es posible que para describir ciertos fenómenos como el experimento de 
                la doble rendija o la reflexión de la luz se deba describir a la luz como ondas y otros fenómenos como 
                la radiación de un cuerpo negro o efecto fotoeléctrico como una partícula?</h2>
                <h2>De aquí nace la idea de la dualidad onda-partícula, en el que las partículas son ondas y 
                partículas al mismo tiempo.</h2>
            </div>

            <div className='dessm'>
                <h1>Referencias</h1>
                <h2>https://openstax.org/books/f%C3%ADsica-universitaria-volumen-3/pages/6-6-dualidad-onda-particula</h2>
            </div>

    </section>
    <Footers />
    </>
  )
}